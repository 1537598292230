// desktop
$cookie-compliance_container-padding-l: 60px;
$cookie-compliance_container-padding-m: 20px;
$cookie-compliance_container-padding-s: 16px;
$cookie-compliance_container-max-width: $msv-breakpoint-xl;
$cookie-compliance_content-width:100%;
$cookie-compliance_accept-btn-margin-left: 48px;
$cookie-compliance_accept-btn-width: 160px;

// mobile
$cookie-compliance_accept-btn-width-mobile: 243px;
$cookie-compliance_accept-content-margin-bottom-mobile:24px;

// style preset
:root {
    // background
    --msv-cookie-compliance-bg-color: var(--msv-bg-color);

    // font
    --msv-cookie-compliance-font-color: var(--msv-font-primary-color);
    --msv-cookie-compliance-font-size: var(--msv-body-font-size-m);

    // link
    --msv-cookie-compliance-link-color: var(--msv-font-primary-color);

    // primary button
    --msv-cookie-compliance-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-cookie-compliance-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-cookie-compliance-primary-btn-border: var(--msv-accent-brand-color);
}

.ms-cookie-compliance {
    background-color: var(--msv-cookie-compliance-bg-color);
    color: var(--msv-cookie-compliance-font-color);
    font-size: var(--msv-cookie-compliance-font-size);

    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 10000;
    padding-top:10px;

    &__container {
        display: flex;
        font-weight: normal;
        max-width: $cookie-compliance_container-max-width;
        margin: 0 auto;

        @media screen and (min-width: $msv-breakpoint-s) {
            padding-left: $cookie-compliance_container-padding-s;
            padding-right: $cookie-compliance_container-padding-s;
        }

        @media (min-width: $msv-breakpoint-m) {
            padding-left: $cookie-compliance_container-padding-m;
            padding-right: $cookie-compliance_container-padding-m;
        }
    }

    &__content {
        width: $cookie-compliance_content-width;
    }

    &__accept-button {
        float: right;
        margin-left: $cookie-compliance_accept-btn-margin-left;
        width: $cookie-compliance_accept-btn-width;

        @include primary-button(var(--msv-cookie-compliance-primary-btn-bg),
        var(--msv-cookie-compliance-primary-btn-font-color), var(--msv-cookie-compliance-primary-btn-border));
    }

    &__cta-layer .link {
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        line-height: 2.2rem;
        color: var(--tebu-klein);
        font-weight: 400;
        font-family: 'Poppins-Medium', sans-serif;

        &::after {
            @include msv-icon();
            content: $msv-ChevronRight;
            color: var(--tebu-klein);
            margin: 0 8px;
        }
        &:hover{
            color: var(--tebu-blue);
            text-decoration: none;

            & > div {
                text-decoration: none;
            }
    
            &::after{
                color: var(--tebu-blue);
            }
        }
    }

    &__text {
        //font-size: var(--msv-cookie-compliance-font-size);
        color: var(--tebu-blue);
        border-color: transparent;
        border: none;
        background-repeat: no-repeat;
        background-position: left center;
        font-size: 1.4rem;
        line-height: 2.2rem;
        font-weight: 400;
        padding: 1.5rem 1rem 1.5rem 50px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEuMDQzMTEwNjIsMjUuNzYzNTA5NCBMMTMuNjkzMjU1MywzLjMxODUzODQyIEMxNC4xMDAwMDc4LDIuNTk2ODQzMjYgMTUuMDE0Nzk1NCwyLjM0MTUzMTc5IDE1LjczNjQ5MDYsMi43NDgyODQzIEMxNS45NzUwNjc5LDIuODgyNzQ4MTkgMTYuMTcyMjgwOCwzLjA3OTk2MTA1IDE2LjMwNjc0NDcsMy4zMTg1Mzg0MiBMMjguOTU2ODg5NCwyNS43NjM1MDk0IEMyOS4zNjM2NDE5LDI2LjQ4NTIwNDYgMjkuMTA4MzMwNCwyNy4zOTk5OTIyIDI4LjM4NjYzNTMsMjcuODA2NzQ0NyBDMjguMTYxODQ2OCwyNy45MzM0MzcgMjcuOTA4MTc3MywyOCAyNy42NTAxNDQ3LDI4IEwyLjM0OTg1NTMsMjggQzEuNTIxNDI4MTgsMjggMC44NDk4NTUzLDI3LjMyODQyNzEgMC44NDk4NTUzLDI2LjUgQzAuODQ5ODU1MywyNi4yNDE5Njc0IDAuOTE2NDE4MjU2LDI1Ljk4ODI5NzkgMS4wNDMxMTA2MiwyNS43NjM1MDk0IFoiIHN0cm9rZT0iIzA3MzNCNiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PGxpbmUgeDE9IjE1IiB5MT0iMTMiIHgyPSIxNSIgeTI9IjE5IiBzdHJva2U9IiMwRjM3QUYiIHN0cm9rZS13aWR0aD0iMiIvPjxsaW5lIHgxPSIxNSIgeTE9IjIyIiB4Mj0iMTUiIHkyPSIyMyIgc3Ryb2tlPSIjMEYzN0FGIiBzdHJva2Utd2lkdGg9IjIiLz48L2c+PC9zdmc+');
        background-position: 10px center;
        background-color: var(--tebu-light-blue);
        text-align: left;
    }
}

@media (max-width: $msv-breakpoint-m) {
    .ms-cookie-compliance {
        &__content {
            margin-bottom: $cookie-compliance_accept-content-margin-bottom-mobile;
        }

        &__accept-button {
            float: none;
            margin: 0;
            width: $cookie-compliance_accept-btn-width-mobile;
        }

        &__container {
            align-items: center;
            flex-direction: column;
        }
    }
}
