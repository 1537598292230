//spacing
$header-container-spacing-desktop: 70px;
$header-container-spacing-mobile-left: 6px;
$header-container-spacing-mobile-right: 12px;
$header-container-spacing-mobile: 0;
$signin-info-spacing-left: 2px;
$navigation-spacing-left: 8px;
$cart-icon-spacing-left: 0px;
$heading-padding-top: 0px;
$signin-info-padding: 12px 14px;
$header-height: 18px;
$header-mobile-height: 48px;
$header-icon-width: 35px;
$account-link-height: 35px;
$account-links-max-width: 276px;
$signin-link-padding: 0 20px;
$signin-link-height: 48px;
$header_container-max-width: $msv-breakpoint-xl;
$header-myaccount-margin-left: 8px;
$header-logo-margin-right: 20px;
$header-logo-margin: auto auto auto 12px;
$header-icon-padding-right: 10px;
$header-icon-padding-left: 10px;
$header-cart-icon-margin-left: 4px;
$header-list-item-link-padding: 0 20px;
$popover-menu-item-height: 48px;
$msv-default-container-padding-bottom: 8px;
$divider-margin-left: 8px;
$divider-margin-right: 8px;
$divider-margin-width: 1px;
$divider-margin-height: 14px;
$divider-padding-m: 10px 0;
$nav-padding-top-m: 20px;
$header-store-selector-btn-padding: 12px 10px;

//style presets
:root {
    --msv-header-bg: var(--msv-bg-color);
    --msv-header-border: var(--msv-border-color);
    --msv-header-font-color: var(--msv-font-primary-color);
    --msv-header-font-size: 14px; //var(--msv-body-font-size-m);
}

header {
    background-color: var(--msv-header-bg);
}

.ms-header {
    background-color: var(--msv-header-bg);
    color: var(--msv-header-font-color);
    border: none; //1px solid var(--msv-header-border);

    margin-left: auto;
    margin-right: auto;
    padding-left: $header-container-spacing-mobile;
    padding-right: $header-container-spacing-mobile;
    padding-top: $heading-padding-top;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    // -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(235, 235, 235, 0.5);
    // box-shadow: 0 0.5rem 1rem 0 rgba(235, 235, 235, 0.5);
    -webkit-box-shadow: 0 0.5rem 1rem 0 #0000002e;
    box-shadow: 0 0.5rem 1rem 0 #0000002e;

    &__container {
        padding-bottom: $msv-default-container-padding-bottom;

        .ms-header__logo {
            margin-right: $header-logo-margin-right;

            a {
                @include vfi();
                display: block;

                &:focus {
                    outline: none;
                    border: none;
                    //outline-offset: -2px;
                }
            }
        }

        .ms-header__logo img {
            // transform: scale(0.55);
            transform-origin: left center;
        }
        @media (max-width: 1599px) {
            .ms-header__logo img {
                transform: scale(1);
            }
        }

        .ms-header__collapsible-hamburger {
            .ms-header__divider {
                background: var(--msv-header-bg);
                border-bottom: 1px solid $msv-gray-300;
                padding: 0; //$divider-padding-m;
            }

            .ms-nav.mobile-vp {
                padding-top: $nav-padding-top-m;
            }
        }
    }

    @media only screen and (max-width: 991px) {
        &__container {
            padding-bottom: 0;
        }
    }

    .ms-header__preferred-store-text {
        color: var(--msv-header-font-color);
        font-size: var(--msv-header-font-size);
        margin: 0 8px;
    }

    .ms-header__preferred-store-btn {
        @include add-icon($msv-Shop-Address);
    }

    .ms-header__preferred-store-btn,
    .ms-search__icon,
    .ms-header__signin-button,
    .msc-wishlist-icon,
    .msc-cart-icon,
    .ms-header__profile-button {
        background-color: transparent;
        color: #fff; //var(--msv-header-font-color);

        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
        justify-content: center;

        &:focus {
            outline-offset: -1px;
        }
    }

    .ms-header__profile-button {
        line-height: 12px;
    }

    .ms-search__form {
        display: block !important;
    }

    .ms-search__form-cancelSearch {
        color: var(--msv-header-font-color);
        display: none;

        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
        justify-content: center;
    }

    .ms-search__form-control,
    .ms-search__form-cancelSearch {
        &:focus {
            outline-offset: -1px;
        }
    }

    // hiding the header icons from login page
    &.hide-header-options {
        .ms-header__nav-icon,
        .ms-header__account-info.account-desktop,
        .ms-header__account-info.account-mobile,
        .msc-cart-icon,
        .ms-header__divider,
        .msc-wishlist-icon {
            display: none;
        }
    }

    .msc-btn {
        height: auto;
        padding: 0;
        border: none;
        font-size: 12px;
        font-family: 'Poppins-Medium', sans-serif;
    }

    .ms-header__topbar {
        align-items: center;
        justify-content: space-between;
        display: flex;
        flex-wrap: nowrap;
        height: $header-mobile-height;
        margin: 2.2rem 0 2.5rem;
    }

    .ms-search {
        display: none;
        position: relative;
        min-width: 250px;
    }

    // @media screen and (max-width: 1100px) {
    //     .ms-search {
    //         min-width: 400px;
    //     }
    // }
    // @media screen and (max-width: 800px) {
    //     .ms-search {
    //         min-width: 300px;
    //     }
    // }
    .catalog-search-wrapper {
        display: none;
        margin-right: 40px;
        position: relative;
        min-width: 380px;
    }
    .ms-search__label {
        display: none;
    }

    .ms-header__desktop-view {
        display: none;
        border-top: 1px solid #ced4da;
    }

    .ms-header__account-info {
        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-s);
        display: none;
        height: auto;
        line-height: 12px;
        justify-content: flex-end;
        //margin-left: $signin-info-spacing-left;

        & .ms-header__profile-button.msc-btn.btn-link {
            padding: 0;

            &:hover,
            &:focus {
                background: none;
                outline: none;
            }
        }

        &__account-link-container {
            //height: $account-link-height;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        a {
            align-items: center;
            display: flex;
            font-size: 1.2rem;
            line-height: 1.8rem;
            padding: $signin-link-padding;
            //height: $signin-link-height;

            &:hover {
                text-decoration: none;
            }
        }

        .ms-header__signin-button {
            background-color: transparent;
            color: #fff;
            font-weight: var(--msv-font-weight-normal);

            display: flex;
            justify-content: flex-start;

            @include add-icon($msv-Contact, before, 400);

            &::before {
                padding-right: $header-icon-padding-right;
                font-size: var(--msv-header-font-size);
            }

            &:focus {
                outline-offset: -1px;
            }
        }

        .msc-popover {
            // background-color: var(--msv-header-bg);
            // background-clip: padding-box;
            // border: 1px solid $msv-gray-500;
            border: 0.1rem solid #d9dbdd;
            border-radius: 0.5rem;
            -webkit-box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.05);
            padding: 0px;
            background: #fff;

            display: block;
            left: 0;
            max-width: $account-links-max-width;
            position: absolute;
            top: 0.9rem !important;
            word-wrap: break-word;
            z-index: 1060;

            & a {
                padding: 1rem 2rem;
                color: var(--tebu-black);
                font-size: 14px;
                line-height: 14px;

                &:hover {
                    background: #f8f8f8;
                    color: var(--tebu-klein);

                    div {
                        text-decoration: none !important;
                    }
                }
            }

            .msc-btn {
                color: var(--msv-header-font-color);
                font-weight: var(--msv-font-weight-normal);
                //height: $popover-menu-item-height;
            }

            .msc-arrow {
                //display: block;
                display: none;
                height: 0.5rem;
                margin: 0 0.3rem;
                position: absolute;
                top: calc((0.5rem + 1px) * -1);
                width: 1rem;

                &::before,
                &::after {
                    border-color: transparent;
                    border-style: solid;
                    border-width: 0 0.5rem 0.5rem 0.5rem;
                    content: '';
                    display: block;
                    position: absolute;
                }

                &::before {
                    border-bottom-color: $msv-gray-500;
                    top: 0;
                }

                &::after {
                    border-bottom-color: var(--msv-border-color);
                    top: 1px;
                }
            }
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-wishlist-icon {
        &:hover {
            text-decoration: none;
        }
    }

    .ms-header__wishlist-desktop {
        align-items: center;
        display: none;
        height: $header-height;
        width: $header-icon-width;

        @include add-icon($msv-Heart, before, 400);

        &::before {
            font-size: var(--msv-header-font-size);
            text-align: center;
            vertical-align: text-bottom;
            width: $header-icon-width;
        }

        .msc-wishlist-icon__text {
            display: none;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .ms-header__nav-icon {
        align-items: center;
        background: var(--msv-header-bg);
        display: flex;
        height: $header-height;
        justify-content: center;
        width: $header-icon-width;

        &:focus {
            outline-offset: -1px;
        }

        &::before {
            @include msv-icon();
            content: $msv-GlobalNavButton;
            font-size: var(--msv-header-font-size);
            width: $header-icon-width;
            vertical-align: text-bottom;
            text-align: center;
        }

        &:hover {
            text-decoration: none;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-cart-icon {
        width: auto; //$header-icon-width;
        height: $header-height;
        display: flex;
        align-items: center;

        // &::before {
        //     @include msv-icon();
        //     content: $msv-ShoppingCart; //$msv-shopping-bag;
        //     font-size: var(--msv-header-font-size);
        //     width: $header-icon-width;
        //     vertical-align: text-bottom;
        //     text-align: center;
        // }

        &:hover {
            text-decoration: none;
        }

        &__count {
            @include font-content-s();
            margin-left: $header-cart-icon-margin-left;
        }
    }

    @media (min-width: $msv-breakpoint-xs) {
        .ms-nav.desktop-vp {
            display: none;
        }

        .msc-cart-icon {
            &::before {
                width: $header-icon-width / 2;
            }
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        padding-left: $header-container-spacing-mobile-left;
        padding-right: $header-container-spacing-mobile-right;

        .msc-wishlist-icon {
            @include add-icon($msv-Heart, before, 400);

            &::before {
                padding-right: $header-icon-padding-right;
            }
        }

        &__container {
            .ms-header__topbar {
                justify-content: space-between;

                .ms-header__logo {
                    margin: $header-logo-margin;
                }

                .ms-site-picker {
                    display: none;
                }
            }

            .ms-header__collapsible-hamburger {
                .ms-nav {
                    .ms-nav__drawer {
                        .drawer__button {
                            @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                            color: var(--msv-header-font-color);
                            background: transparent;
                            text-align: left;
                            width: 100%;

                            .ms-nav__drawer-open {
                                @include add-icon($msv-ChevronUp, after);
                            }

                            .ms-nav__drawer-close {
                                @include add-icon($msv-ChevronDown, after);
                            }

                            .drawer__glyph__end {
                                position: absolute;
                                right: 10px;
                            }
                        }

                        .msc-btn {
                            outline-offset: -1px;
                        }
                    }

                    .ms-nav__list__item__link {
                        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                        align-items: center;
                        display: flex;
                        padding: $header-list-item-link-padding;
                    }

                    & > .ms-nav__list__item__link {
                        padding: 0;
                    }
                }
            }
        }

        .ms-header__account-info.account-mobile {
            display: block; //unset;
            margin-left: 0;
            padding-top: 10px;

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .drawer__button {
                @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                @include add-icon($msv-Contact, before, 400);

                &::before {
                    padding-right: $header-icon-padding-right;
                }
                background: transparent;
                text-align: left;
                width: 100%;

                .ms-header__drawer-open {
                    @include add-icon($msv-ChevronUp, after);
                }

                .ms-header__drawer-close {
                    @include add-icon($msv-ChevronDown, after);
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .drawer__glyph__end {
                    position: absolute;
                    right: $header-icon-padding-right;
                }
            }

            .ms-header__signin-button {
                display: flex;
                justify-content: flex-start;
                color: var(--tebu-black);

                @include add-icon($msv-Contact, before, 400);

                &::before {
                    padding-right: $header-icon-padding-right;
                }
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .msc-btn {
                outline-offset: -1px;
            }
        }

        .ms-header__collapsible-hamburger {
            background: var(--msv-header-bg);
            left: 0;
            margin-top: 0px;
            position: absolute;
            width: 100%;
            z-index: 1012;
            padding: 20px;
            max-height: 600px;
            overflow-y: scroll;

            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */

            &::-webkit-scrollbar {
                display: none;
            }

            .ms-header__wishlist-mobile {
                // text-align: left;
                // width: 100%;
                display: none;
            }

            .catalog-search-wrapper {
                margin-bottom: 15px;
                margin-right: 0;
            }

            .ms-search,
            .catalog-search-wrapper {
                justify-content: flex-start;
                display: flex;

                .ms-search__form {
                    position: relative;
                }

                .ms-search__label {
                    display: none; //flex;
                    width: 100%;

                    .ms-search__icon {
                        display: flex;
                        justify-content: flex-start;
                        width: 100%;
                    }

                    .ms-search__icon-text {
                        display: unset;
                        padding-left: $header-icon-padding-left;
                    }
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-l) {
        padding-left: $header-container-spacing-desktop;
        padding-right: $header-container-spacing-desktop;

        .ms-header__desktop-view {
            display: block;
        }

        .ms-nav.desktop-vp {
            display: flex;
        }

        &__modal {
            display: none;
        }

        &__profile-button {
            color: var(--msv-header-font-color);
            font-weight: var(--msv-font-weight-normal);
            background: var(--msv-header-bg);

            &::after {
                @include msv-icon();
                content: $msv-ChevronDown;
                font-size: 12px;
                margin-left: $header-myaccount-margin-left;
                text-align: center;
                //vertical-align: text-bottom;
            }
        }

        &__profile-button[aria-expanded='true'] {
            &::after {
                content: $msv-ChevronUp;
            }
        }

        &__profile-button[aria-expanded='false'] {
            &::after {
                content: $msv-ChevronDown;
            }
        }

        &__divider {
            background: $msv-divider-color;
            height: $divider-margin-height;
            margin-left: $divider-margin-left;
            margin-right: $divider-margin-right;
            width: $divider-margin-width;
        }

        .ms-header__account-info.account-desktop {
            display: block;
        }

        .ms-header__collapsible-hamburger {
            display: none;

            .ms-header__wishlist-mobile {
                text-align: left;
                width: 100%;
            }
        }

        .ms-search,
        .catalog-search-wrapper {
            display: flex;

            &__icon {
                align-items: center;
                color: var(--msv-header-font-color);
                font-weight: normal;

                &:focus {
                    outline-offset: -1px;
                }
            }
        }

        .ms-header__wishlist-desktop {
            display: flex;
        }

        .ms-header__nav-icon {
            display: none;
        }

        .ms-cart-icon {
            padding-left: $cart-icon-spacing-left;
            width: unset;

            &::before {
                width: unset;
                margin-right: 4px;
            }
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        .ms-header {
            &__preferred-store-btn {
                padding: $header-store-selector-btn-padding;
            }

            &__preferred-store-text {
                display: none;
            }
        }
    }

    @supports (-webkit-touch-callout: none) {
        .ms-header__preferred-store-btn {
            &:focus {
                border: none;
                outline: 0;
            }
        }
    }
}

/******************************************************
* Top Header
******************************************************/
.header__top {
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #ffffff;
    background-color: #ffffff;
    padding: 1rem 0;
    max-height: 5rem;
    display: none;
    font-family: 'Poppins-Medium', sans-serif;
    font-weight: 400;
}

.header__top a:hover {
    color: #fff;
}
.header__top .msc-popover-inner a:hover,
.header__top .msc-flyout a:hover {
    color: var(--tebu-blue);
}

.top-icons-mobile {
    display: flex;
    align-items: center;
}
.top-icons-mobile a {
    color: var(--tebu-black) !important;
}
.top-icons-mobile .ms-cart-icon a {
    &::before {
        @include msv-icon();
        content: $msv-ShoppingCart;
        font-size: var(--msv-header-font-size);
        width: $header-icon-width;
        vertical-align: text-bottom;
        text-align: center;
    }
    .msc-cart-icon__count {
        margin: 0 4px 0 0;
    }
}
.top-icons-mobile .msc-wishlist-icon {
    display: flex;
    @include add-icon($msv-Heart, before, 400);

    & span {
        display: none;
    }
    &::before {
        padding-right: $header-icon-padding-right;
        color: var(--tebu-black);
    }
}
@media only screen and (min-width: 992px) {
    .header__top {
        background-color: var(--tebu-blue);
        max-height: 3rem;
        padding: 0.5rem 70px 1rem;
        margin-left: -70px;
        margin-right: -70px;
        display: flex;
    }
    .top-icons-mobile {
        display: none;
    }
}

.topHeaderRight {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}
.header__top .icon {
    width: 2rem;
    height: 2rem;
    margin-top: -0.2rem;
    margin-right: 0.8rem;
}
.top-header-cart {
    display: flex !important;
    justify-content: center;
}
.top-header-cart .icon.icon-cart {
    margin-right: 0;
    margin-left: 10px;
}

.tetiere {
    position: relative;
    overflow: hidden;
    max-height: 31.5rem;

    & .ms-content-block__details {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        opacity: 1;
        overflow: visible;

        // top: -200px;
        // height: 700px;
        // width: 700px;
        // border-top-right-radius: 50%;
        // border-bottom-right-radius: 50%;
        // padding-left:80px ;

        width: calc(315 / 1600 * 100%);
        height: 0;
        padding-bottom: calc(315 / 1600 * 100%);
        top: 50%;
        left: calc(95 / 1600 * 100%);
        -webkit-transform: translateY(-50%) scale(2);
        transform: translateY(-50%) scale(2);
        border-radius: 50%;

        & .ms-content-block__text {
            position: absolute;
            top: 50%;
            left: 8vw; //calc(25vw - 300px);
            margin-left: 0px;
            -webkit-transform: translateY(-50%) scale(1);
            transform: translateY(-50%) scale(1);
            width: 80vh;

            & h1 {
                color: #fff;
                margin: 0;
                font-size: 2.5rem;
                line-height: 3.8rem;
                font-weight: 400 !important;
            }

            & p {
                color: #fff;
                letter-spacing: 0.07em;
                margin-bottom: 0;
                font-size: 0.8rem;
                line-height: 1.4rem;
                text-transform: uppercase;
                font-family: 'Poppins-SemiBold', sans-serif;
            }
        }

        @media screen and (max-width: 1200px) {
            & .ms-content-block__text {
                //left: calc(25vw - 300px);

                & h1 {
                    // font-size: 1.5rem;
                    // line-height: 2.1rem;
                }
            }
        }
        @media screen and (max-width: 1000px) {
            & .ms-content-block__text {
                & h1 {
                    font-size: 1.8rem;
                    line-height: 2.3rem;
                }
            }
        }
        @media screen and (max-width: 768px) {
            width: calc(360 / 1600 * 100%);
            padding-bottom: calc(360 / 1600 * 100%);

            & .ms-content-block__text {
                //left: calc(25vw - 300px);

                & p {
                    font-size: 0.6rem;
                    line-height: 0.8rem;
                }
                & h1 {
                    font-size: 1.3rem;
                    line-height: 1.7rem;
                }
            }
        }
    }

    &.tetiere-blue .ms-content-block__details {
        background: -webkit-gradient(linear, left top, right top, from(rgba(0, 230, 190, 0.9)), to(rgba(0, 130, 245, 0.9)));
        background: -o-linear-gradient(left, rgba(0, 230, 190, 0.9), rgba(0, 130, 245, 0.9));
        background: linear-gradient(to right, rgba(0, 230, 190, 0.9), rgba(0, 130, 245, 0.9));
    }

    &.tetiere-orange .ms-content-block__details {
        background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 140, 100, 0.9)), to(rgba(255, 230, 125, 0.9)));
        background-image: -o-linear-gradient(left, rgba(255, 140, 100, 0.9), rgba(255, 230, 125, 0.9));
        background-image: linear-gradient(to right, rgba(255, 140, 100, 0.9), rgba(255, 230, 125, 0.9));
    }

    &.tetiere-purple .ms-content-block__details {
        background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 175, 255, 0.9)), to(rgba(165, 95, 250, 0.9)));
        background-image: -o-linear-gradient(left, rgba(0, 175, 255, 0.9), rgba(165, 95, 250, 0.9));
        background-image: linear-gradient(to right, rgba(0, 175, 255, 0.9), rgba(165, 95, 250, 0.9));
    }

    &.tetiere-green .ms-content-block__details {
        background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 230, 190, 0.9)), to(rgba(255, 230, 125, 0.9)));
        background-image: -o-linear-gradient(left, rgba(0, 230, 190, 0.9), rgba(255, 230, 125, 0.9));
        background-image: linear-gradient(to right, rgba(0, 230, 190, 0.9), rgba(255, 230, 125, 0.9));
    }

    // @media screen and (max-width: 992px) {
    //     & .ms-content-block__details {
    //         //top: -260px;
    //         // top: -180px;
    //         // height: 400px;
    //         // width: 400px;
    //         // justify-content: flex-start;
    //         // padding-left: 40px;
    //         // padding-right: 0;

    //         & p {
    //             margin-bottom: 0;
    //         }
    //     }
    // }

    // @media screen and (max-width: 768px) {
    //     & .ms-content-block__details {
    //         // top: -180px;
    //         // height: 500px;
    //         // width: 500px;

    //         & p {
    //             margin-bottom: 0rem;
    //         }
    //     }
    // }

    // @media screen and (max-width: 640px) {
    //     & .ms-content-block__details {
    //         // top: -150px;
    //         // height: 400px;
    //         // width: 400px;
    //         // padding-left: 20px;

    //         & p {
    //             margin-bottom: 0rem;
    //         }
    //     }
    // }

    & .ms-content-block__image {
        width: 100%;
        height: 100%;

        & picture {
            width: 100%;
            height: 100%;

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                max-height: 31.5rem;
            }
        }
    }
}

.ms-header.aad_header {
    & .ms-header__topbar {
        justify-content: center;
    }
    & .ms-header__desktop-view {
        display: none;
    }
}

.header-search-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media screen and (max-width: 768px) {
    .msc-cart-icon__count,
    .msc-wishlist-icon__count {
        display: none;
    }
}

.mobile-menu-contact {
    color: var(--tebu-black);
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding: 10px 18px;
    font-family: 'Poppins-Medium', sans-serif;
}
.mobile-menu-contact:hover {
    text-decoration: none;
    color: var(--tebu-black);
}

.ms-header__collapsible-hamburger i.icon.icon-email {
    width: 16px;
    height: 2rem;
    margin-right: 8px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0zLDUgTDkuMDU2ODA5MTMsMTAuMTkxNTUwNyBDOS42MDM5NjQ4OSwxMC42NjA1NDEzIDEwLjQwNzMwODIsMTAuNjc0MTUzNCAxMC45NzAwMzg4LDEwLjIyMzk2OSBMMTUsNyBMMTUsNyIgc3Ryb2tlPSIjNjc2OTY3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PHBhdGggZD0iTTMuNSwxNiBMMTYuNSwxNiBDMTcuMzI4NDI3MSwxNiAxOCwxNS4zMjg0MjcxIDE4LDE0LjUgTDE4LDUuNSBDMTgsNC42NzE1NzI4OCAxNy4zMjg0MjcxLDQgMTYuNSw0IEwzLjUsNCBDMi42NzE1NzI4OCw0IDIsNC42NzE1NzI4OCAyLDUuNSBMMiwxNC41IEMyLDE1LjMyODQyNzEgMi42NzE1NzI4OCwxNiAzLjUsMTYgWiIgc3Ryb2tlPSIjNjc2OTY3IiBzdHJva2Utd2lkdGg9IjEuNSIvPjwvZz48L3N2Zz4=');
}
