
//Variables
$site-picker-desktop-list-bg-color: #F3F3F3;
$site-picker-list-item-img-padding: 10px 10px 1px 0;
$site-picker-list-margin: 5px;
$site-picker-list-item-padding-top: 10px;
$site-picker-list-item-padding-bottom: 10px;
$site-picker-list-item-padding-left: 10px;
$site-picker-list-item-padding-right: 10px;
$site-picker-line-height: 40px;
$site-picker-height: 48px;
$site-picker-transparent: transparent;

//style presets
:root {
    --ms-site-picker-font-size: var(--msv-body-font-size-m);
    --ms-site-picker-color: var(--msv-font-primary-color);
    --ms-site-picker-gray-500: #{$msv-gray-500};
    --ms-site-picker-bg-color: #{$site-picker-transparent};
    --ms-site-picker-desktop-list-bg-color: #{$site-picker-desktop-list-bg-color};
    --ms-site-picker-mobile-list-bg-color: #{$msv-white};
    --ms-site-picker-font-color: #676967;
}

.ms-site-picker {
    z-index: 1015;
    margin-left: 0;
    align-self: flex-start;
    align-items: self-end;
    // font-size: var(--ms-site-picker-font-size);
    // height: $site-picker-height;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #ffffff;
    position: relative;

    &__button {
        background-color: var(--ms-site-picker-bg-color);
        border: none;
        color: #fff;
        cursor: pointer;
        height: 100%;
        width: 100%;
        font-size: 1.2rem;
        line-height: 1.8rem;
        padding: 0;

        &:focus {
            //border: 1px dashed var(--ms-site-picker-gray-500);
            border: none;
            outline: none;
            background-color: var(--ms-site-picker-bg-color);
        }

        &:hover{
            background-color: var(--ms-site-picker-bg-color);
        }
    }

    &__span {
        &::after {
            @include msv-icon();
            content: $msv-ChevronDown;
            color: #fff;
            margin: 0 8px;
            //line-height: $site-picker-line-height;
        }
    }

    &__list {
        //background-color: var(--ms-site-picker-desktop-list-bg-color);
        display: block;
        list-style-type: none;
        padding-left: 0;
        margin: $site-picker-list-margin;
        width: 240px;
        position: absolute;
        transform: translate3d(0px, 26px, 0px);
        top: 0px;
        left: 0px;
        will-change: transform;
        max-height: 25rem;
        overflow-y: auto;
        margin-top: 1.6rem;
        padding: 0;
        border: 0.1rem solid #D9DBDD;
        border-radius: 0.5rem;
        -webkit-box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.05);
        -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
        transition: -webkit-box-shadow 0.3s ease-in-out;
        -o-transition: box-shadow 0.3s ease-in-out;
        transition: box-shadow 0.3s ease-in-out;
        transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
        border: none;
        outline: 0.1rem solid #D9DBDD;
        background: #fff;

        &::-webkit-scrollbar{
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            background: #EEEEEE;
            border: thin solid #DFDFDF;
            box-shadow: 0 0 3px #DFDFDF inset;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: #999999;
            border: thin solid #323130;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #7D7D7D;
        }


        &__item {
            cursor: pointer;

            &__link {
                color: var(--ms-site-picker-font-color);
                flex-direction: row-reverse;
                justify-content: flex-end;
                padding-bottom: $site-picker-list-item-padding-bottom;
                padding-left: $site-picker-list-item-padding-left;
                padding-top: $site-picker-list-item-padding-top;
                padding-right: $site-picker-list-item-padding-right;
                align-items: flex-end;
                width: 100%;
                font-size: 14px;
                line-height: 21px;

                &:focus,
                &:hover {
                    //border: px dashed var(--ms-site-picker-gray-500);
                    outline: none;
                    background-color: #F8F8F8;
                    color: var(--ms-site-picker-font-color) !important;
                    text-decoration: none !important;
                }

                // &:hover{
                //     color: var(--ms-site-picker-font-color) !important;
                // }
            }

            &__img {
                padding: $site-picker-list-item-img-padding;
            }
        }
    }
}

@media (max-width: $msv-breakpoint-l) {
    .ms-site-picker {
        margin-left: 0;
        height: auto;
    }

    .ms-site-picker__drawer {
        .drawer__button {
            background-color: var(--ms-site-picker-bg-color);
            border: none;
            color: var(--ms-site-picker-font-color);
            cursor: pointer;
            width: 100%;
            font-size: 1.2rem;
            padding-left: 20px;
            padding-right: $site-picker-list-item-padding-right;
            height: $site-picker-height;
            outline-offset: -1px;

            .drawer__buttontext {
                float: left;
            }

            .ms-site-picker__drawer-close {
                position: absolute;
                right: 10px;

                &::after {
                    @include msv-icon();
                    color: var(--ms-site-picker-color);
                    content: $msv-ChevronDown;
                }
            }

            .ms-site-picker__drawer-open {
                position: absolute;
                right: 10px;

                &::after {
                    @include msv-icon();
                    color: var(--ms-site-picker-color);
                    content: $msv-ChevronUp;
                }
            }
        }

        .ms-site-picker__list__item__link {
            flex-direction: row-reverse;
            justify-content: flex-end;
            background-color: var(--ms-site-picker-mobile-list-bg-color);
            color: var(--ms-site-picker-font-color);
            height: $site-picker-height;
            font-size: 1.2rem;
            padding-bottom: $site-picker-list-item-padding-bottom;
            padding-left: 20px;
            padding-right: $site-picker-list-item-padding-right;
            align-items: flex-end;
            width: 100%;

            &:focus {
                border: 1px dashed var(--ms-site-picker-gray-500);
                outline: none;
            }

            .ms-site-picker__list__item__text {
                margin: auto 0;
            }
        }
    }
}


@media only screen and (max-width: 992px) {
    .ms-site-picker__drawer .ms-site-picker__list__item__link{
        align-items: center;
    }
    .ms-site-picker__drawer .ms-site-picker__list__item__img {
        padding-top: 0;
    }
}

@media only screen and (max-width: 768px) {
    .ms-site-picker{
        overflow-y: scroll;
        max-height: 250px;

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }
    }
}