$msv-order-summary-font: $msv-primary-font-family;
$msv-order-summary-padding: 32px;
$msv-order-summary-heading-padding-bottom: 2rem;
$msv-order-summary-items-border-radius: 2px;
$msv-order-summary-label-line-height: 20px;
$msv-order-summary-line-padding: 5px 0;

//style presets
:root {
    --msv-order-summary-bg: var(--msv-bg-color);
    --msv-order-summary-font-color: var(--msv-font-primary-color);
    --msv-order-summary-font-size: var(--msv-body-font-size-l);
    --msv-order-summary-border: var(--msv-font-primary-color);

    // heading
    --msv-order-summary-heading-font-color: var(--tebu-blue);
    --msv-order-summary-heading-font-size: 2rem;
}

.msc-order-summary {
    &-wrapper {
        align-items: center;
        background-color: var(--msv-order-summary-bg);
        display: flex;
        flex: 0 0 368px;
        flex-direction: column;
        margin: 0 auto;
        padding: $msv-order-summary-padding;
        height: fit-content;

        & .msc-order-summary__heading {
            @include font-content(var(--msv-font-weight-heavy),
            var(--msv-order-summary-heading-font-size), 2.8rem);
            color: var(--msv-order-summary-heading-font-color);
            font-style: $msv-order-summary-font;
            padding-bottom: $msv-order-summary-heading-padding-bottom;
            width: 100%;
            margin:0;
        }
    
        & .msc-order-summary__line-sub-total{
            margin: 2.5rem 0 0 0;
            padding: 2.5rem 0 1.5rem;
            border-top: 0.1rem solid #D9DBDD;
            font-size: 1.6rem;
            line-height: 2.2rem;
        }

        & p{
            font-size: 1.6rem;
            line-height: 2.2rem;
        } 

        & .msc-order-summary__line-tax-amount{
            margin: 2.5rem 0 0 0;
            padding: 2.5rem 0 1.5rem;
            border-top: 0.1rem solid #D9DBDD;
            font-size: 1.6rem;
            line-height: 2.2rem;
        }

        & .msc-cart__btn-backtoshopping{
            background-color: #fff;
            border: 1px solid var(--tebu-blue);
            border-radius: 0.5rem;
            color: var(--tebu-blue);
            padding: 1.6rem 5rem;
            position: relative;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            font-size: 1.5rem;
            line-height: 1.8rem;
            font-weight: 400;
            font-family: 'Poppins-SemiBold', sans-serif;
        
            &:hover,
            &:focus {
                color: #fff;
                text-decoration: none;
            }

            &::before{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
                background: var(--tebu-blue);
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition: -webkit-transform 0.5s ease-out;
                transition: -webkit-transform 0.5s ease-out;
                -o-transition: transform 0.5s ease-out;
                transition: transform 0.5s ease-out;
                transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
                border-radius: 0.5rem;                
            }

            &:hover::before,
            &:focus::before{
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }
        }
    }

    @media screen and (max-width: $msv-breakpoint-s) {
        margin: unset;
    }

    &__items {
        color: var(--msv-order-summary-font-color);
        border-radius: $msv-order-summary-items-border-radius;

        @include font-content(var(--msv-font-weight-normal), var(--msv-order-summary-font-size), $msv-line-height-l);
        width: 100%;

        .msc-promo-code-heading{
            font-size: 1.4rem;
            line-height: 2.2rem;
        }
        .msc-promo-code {
            &__group {
                display: flex;
                padding-top: 10px;
            }

            &__input-box {
                flex: auto;
                margin-right: 20px;
                line-height: 3rem;
                padding: 4px 8px;
                font-size: 1.4rem;
                width: 65%;
                border-radius: 0.5rem;
                border: 0.1rem solid #D9DBDD;
            }

            &__apply-btn{
                width: calc(35% -20px);
                min-width: auto;
                background-color: var(--tebu-blue);
                border: none;
                border-radius: 0.5rem;
                color: var(--tebu-green);
                padding: 1.6rem 5rem;
                position: relative;
                -webkit-transform: perspective(1px) translateZ(0);
                transform: perspective(1px) translateZ(0);
                font-size: 1.5rem;
                line-height: 1.8rem;
                cursor: pointer;

                &:hover,
                &:focus {
                    color: var(--tebu-blue);
                }  
                
                &::before{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                    background: var(--tebu-green);
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transform-origin: 0 50%;
                    transform-origin: 0 50%;
                    -webkit-transition: -webkit-transform 0.5s ease-out;
                    transition: -webkit-transform 0.5s ease-out;
                    -o-transition: transform 0.5s ease-out;
                    transition: transform 0.5s ease-out;
                    transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
                    border-radius: $btn-border-radius;
                }

                &:hover::before,
                &:focus::before{
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                }
            }
        }
    }

    &__line {
        &-net-total,
        &-net-price,
        &-tax-amount,
        &-shipping,
        &-loyalty,
        &-gift-card,
        &-other-charges,
        &-total-discounts {
            padding: 10px 0 10px;
        }

        &-total {
            @include font-content(var(--msv-font-weight-normal),
            var(--msv-order-summary-font-size), $msv-line-height-l);
            padding: 10px 0 20px;
            border-top: 1px solid var(--msv-order-summary-border);
            text-transform: uppercase;
        }
    }

    &__value {
        float: right;
        text-align: right;
        font-weight: var(--msv-font-weight-bold);
    }
}

@media screen and (min-width: $msv-breakpoint-l) {
    .msc-order-summary-wrapper {
        margin: 0;
    }
}
