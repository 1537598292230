// $homepage-hero-title-font-size: 64px;
// $homepage-hero-title-line-height: 1.2;

:root {
    //Text theme
    // --msv-hero-textheme-dark: var(--msv-font-primary-color);
    // --msv-hero-textheme-light: var(--msv-font-secondary-color);

    // // Heading
    // --msv-hero-heading-size: #{$homepage-hero-title-font-size};

    // //Body
    // --msv-hero-body-size: #{$msv-font-size-m};

    // //Cta
    // --msv-hero-cta-color: var(--msv-accent-brand-color);
}

.article-items{

    & .article-item {
        border: 1px solid #ced4da;
        list-style: none;
        padding: 10px;
        margin: 20px 0;

        &-temp{
            background: gold;
            margin-bottom: 20px;
        }
    
        &-excerpt, &-content{
            font-size: 9px !important;
            line-height: 14px !important;
        }
    }
}

@media screen and (max-width: 1300px) {

}

@media screen and (max-width: $msv-breakpoint-m) {

}
