@import 'slick';
@import 'slick-theme';

.slick-arrow {
    font-size: 0;
    z-index: 1;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border: none;
    padding: 0;
    background-color: #FFFFFF;
    border-radius: 50%;
    -webkit-box-shadow: 0 0.4rem 2.3rem rgba(0, 0, 0, 0.08);
    box-shadow: 0 0.4rem 2.3rem rgba(0, 0, 0, 0.08);
}

.slick-prev {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    left: -2.5rem;
    width: 5.4rem;
    height: 5.4rem;
    cursor: pointer;
}

.slick-prev:before {
    content: "";
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    background-image: url('data:image/svg+xml;utf8, <svg viewBox="0 0 5 9" xmlns="http://www.w3.org/2000/svg"><polyline stroke="rgb(74%2C72%2C74)" stroke-width="1.5" transform="rotate(-45 .5 4.5)" points="3 2 3 7 -2 7" fill="none" stroke-linejoin="round"/></svg>');
    width: 1.2rem;
    height: 2.4rem;
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 1.8rem;
}

.slick-next {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    right: -2.5rem;
    width: 5.4rem;
    height: 5.4rem;
    cursor: pointer;
}

.slick-next:before {
    content: "";
    background-image: url('data:image/svg+xml;utf8, <svg viewBox="0 0 5 9" xmlns="http://www.w3.org/2000/svg"><polyline stroke="rgb(74%2C72%2C74)" stroke-width="1.5" transform="rotate(-45 .5 4.5)" points="3 2 3 7 -2 7" fill="none" stroke-linejoin="round"/></svg>');
    width: 1.2rem;
    height: 2.4rem;
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 1.8rem;
}