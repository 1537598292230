@import "button";
@import "collapse";
@import "form";
@import "image";
@import "reset";


.ms-content-block__cta a:hover,
.ms-content-block__cta a:hover div{
    text-decoration: none !important;
}

.ms-content-block__details li::marker{
    color: var(--tebu-blue);    
    font-size: 1.4rem;
}