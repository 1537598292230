// Style preset
:root {
    // rows
    --msv-product-specification-row-odd-color: #{$msv-gray-100};
    --msv-product-specification-row-even-color: #{$msv-white};
    --msv-product-specification-border: #{$msv-gray-300};

    // heading
    --msv-product-specification-heading-font-color: var(--msv-font-primary-color);
    --msv-product-specification-heading-font-size: var(--msv-body-font-size-xl);

    // body
    --msv-product-specification-title-font-color: var(--msv-font-primary-color);
    --msv-product-specification-text-font-color: var(--msv-font-primary-color);
    --msv-product-specification-body-font-size: var(--msv-body-font-size-m);
}

.ms-product-specification {
    &__heading {
        @include font-content(var(--msv-font-weight-normal), var(--msv-product-specification-heading-font-size), $msv-line-height-xl);
        color: var(--msv-product-specification-heading-font-color);
        margin-top: 40px;
        margin-bottom: 20px;
    }

    &__table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;
    }

    &__table-row {
        &:nth-of-type(odd) {
            background-color: var(--msv-product-specification-row-odd-color);
        }

        &:nth-of-type(even) {
            background-color: var(--msv-product-specification-row-even-color);
        }

        th {
            @include font-content(var(--msv-font-weight-bold), var(--msv-product-specification-body-font-size), $msv-line-height-m);
            color: var(--msv-product-specification-title-font-color);
            border: 1px solid var(--msv-product-specification-border);
            padding: 0.75rem;
            text-align: left;
        }

        td {
            @include font-content(var(--msv-font-weight-normal), var(--msv-product-specification-body-font-size), $msv-line-height-m);
            color: var(--msv-product-specification-text-font-color);
            border: 1px solid var(--msv-product-specification-border);
            padding: 0.75rem;
        }
    }
}

.wrapper-specifications .specification{
    padding-right: 2.8rem;
}
.specification__title {
    color: var(--tebu-blue);
    font-size: 2rem;
    line-height: 3rem;
    padding-bottom: 2rem;
    font-weight: 700;
}
.specification__desc {
    color: #676967;
    font-size: 1.4rem;
    line-height: 2.2rem;
    border-top: 0.1rem solid #d9dbdd;
    border-bottom: 0.1rem solid #d9dbdd;
    padding: 3rem 0;
}
.specification__desc.empty_desc{
    padding: 0;
    border-bottom: none;
}
.specification__detail {
    padding: 2rem 0;

    & .specification__desc{
        font-size: 1.4rem;
        line-height: 2.1rem;
    }

    &--item{
        font-size: 1.4rem;
        line-height: 2.1rem;
        padding-bottom: 1rem;
        display: flex;
        justify-content: flex-start;

        & label{
            font-weight: 700;

            &::after{
                content: ":";
                padding: 0 5px;
            }
        }

        & .specification-item-value{
            overflow-wrap: anywhere;
        }
    }
}

.resources-block{
    margin-bottom: 3rem;
    padding: 0;
    background-color: #fff;
    border: 0.1rem solid #d9dbdd;
    border-radius: 0.5rem;
    -webkit-box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.05);

    .drawer__button{
        width: 100%;
        background: transparent;
        border: none;
        cursor: pointer;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 700;
        text-align: left;
        color: var(--tebu-black);
        padding: 3rem 3rem 3rem 8rem;
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGxpbmUgeDE9IjE5LjAxMSIgeTE9IjI0LjYxIiB4Mj0iMTEuMTU3IiB5Mj0iMjQuNjEiIHN0cm9rZT0iIzY3Njk2NyIgc3Ryb2tlLXdpZHRoPSIyIi8+PHBvbHlsaW5lIHN0cm9rZT0iIzY3Njk2NyIgc3Ryb2tlLXdpZHRoPSIyIiBwb2ludHM9IjE4LjcwMjcgMTcuNzQyOCAxNS4xMjc3IDIxLjMxNzggMTEuNTUyNyAxNy43NDI4Ii8+PGxpbmUgeDE9IjE1LjEyNyIgeTE9IjIwLjI3MSIgeDI9IjE1LjEyNyIgeTI9IjEzLjMxMiIgc3Ryb2tlPSIjNjc2OTY3IiBzdHJva2Utd2lkdGg9IjIiLz48cG9seWxpbmUgc3Ryb2tlPSIjNjc2OTY3IiBzdHJva2Utd2lkdGg9IjIiIHBvaW50cz0iMjYuMTEwOSAxOC41ODc1IDI2LjExMDkgOC44OTM1IDE4LjI3NDkgMS4wNTc1IDQuMDU2OSAxLjA1NzUgNC4wNTY5IDI5LjEzMjUgMjYuMTEwOSAyOS4xMzI1IDI2LjExMDkgMjQuODg4NSIvPjxwb2x5bGluZSBzdHJva2U9IiM2NzY5NjciIHN0cm9rZS13aWR0aD0iMiIgcG9pbnRzPSIxNy42OTIgMS4yOTM2IDE3LjY5MiAxMC4xMjk2IDI1Ljg3NSAxMC4xMjk2Ii8+PC9nPjwvc3ZnPg==") left 3rem center no-repeat;

        @include add-icon($msv-ChevronDown, after);
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        &::after{
            -webkit-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            color: var(--tebu-blue);
        }

        &[aria-expanded=true]::after{
            transform: rotate(180deg);
        }
    }

    .resource-item a{
        display: flex;
        flex-direction: row;
        text-decoration: none !important;
        align-items: center;
        width: 100%;
        position: relative;
        font-size: 1.4rem;
        line-height: 2.2rem;
    
        padding: 3rem 3rem 3rem 8rem;
        width: 100%;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PHBvbHlsaW5lIHN0cm9rZT0iIzA1RiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHBvaW50cz0iMTUuNzU0Mjc0MyA4IDkuOTk2ODkzNzkgMTQgNC4yMzk1MTMyNCA4Ii8+PGxpbmUgeDE9IjE2LjI1MSIgeTE9IjE5IiB4Mj0iMy42MDMiIHkyPSIxOSIgc3Ryb2tlPSIjMDVGIiBzdHJva2Utd2lkdGg9IjEuNSIvPjxsaW5lIHgxPSI5Ljk5NyIgeTE9IjEzIiB4Mj0iOS45OTciIHkyPSIxIiBzdHJva2U9IiMwNUYiIHN0cm9rZS13aWR0aD0iMS41Ii8+PC9nPjwvc3ZnPg==');
        background-repeat: no-repeat ;
        background-position: left 3.2rem center;
        color: var(--tebu-blue);
        border-top: 0.1rem solid #D9DBDD;

        cursor: pointer;
    
        &:hover {
            -webkit-box-shadow: 0 0.5rem 3rem 0 rgba(0, 0, 0, 0.05), 0 3rem 2rem 1rem rgba(0, 0, 0, 0.05);
            box-shadow: 0 0.5rem 3rem 0 rgba(0, 0, 0, 0.05), 0 3rem 2rem 1rem rgba(0, 0, 0, 0.05);
        }
    }
}


.clamp {
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
}

.specification-more, .default-specifications-more, .default-specifications-less{
    margin-top: 2rem;
    font-size: 1.3rem;
    line-height: 2.2rem;
    color: var(--tebu-klein);
    display: flex;
    align-items: center;
    cursor: pointer;

    &::after{
        content: '';
        width: 10px;
        height: 10px;
        background-image: url('data:image/svg+xml;utf8, <svg viewBox="0 0 5 9" xmlns="http://www.w3.org/2000/svg"><polyline stroke="rgb(0%2C85%2C255)" stroke-width="1.5" transform="rotate(-45 .5 4.5)" points="3 2 3 7 -2 7" fill="none" stroke-linejoin="round"/></svg>');
        background-repeat: no-repeat;
        background-position: center center;
        margin-left: 5px;
    }

    &:hover{
        color: var(--tebu-blue);
    }
}

.default-specifications-more::after{
    transform: rotate(90deg);
}

.default-specifications-less::after{
    transform: rotate(-90deg);
}

.d365-mkt-config{
    display: none;
}