$msv-dialog-width: 420px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;
$msv-dialog-modal-block-margin-side: 20px;
$msv-dialog-modal-image-font-size: 84px;

:root {
    // body
    --msc-modal-success-image-bg: #{$msv-gray-300};
    --msv-modal-success-alert-font-color: var(--msv-font-primary-color);
    --msv-modal-success-alert-border: #{$msv-gray-900};
    --msv-modal-font-size: var(--msv-body-font-size-m);
}

.msc-add-order-template-to-cart.msc-btn {
    @include primary-button();
    @include vfi();

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }
    }
}

.msc-modal {
    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-add-order-template-to-cart {
        &__dialog {
            max-width: $msv-dialog-width;

            &__image {
                margin-right: auto;
                margin-left: auto;
                margin-bottom: $msv-dialog-modal-block-margin-side;
                font-size: $msv-dialog-modal-image-font-size;
            }

            &__process-status {
                padding: $msv-dialog-modal-content-body-text-padding;
                margin-bottom: $msv-dialog-modal-block-margin-side;

                @include font-content(var(--msv-font-weight-bold), var(---msv-modal-font-size), $msv-line-height-m);
            }

            .msc-modal__content {
                padding: $msv-dialog-modal-content-padding;
            }

            &__back-to-order-template {
                @include secondary-button();
                margin: $msv-dialog-modal-content-button-margin;
                width: $msv-dialog-button-width;
            }

            &__body {
                @include font-content-l();
                text-align: center;

                &__loading {
                    .msc-add-order-template-to-cart__dialog__image {
                        @include add-icon($msv-Spinner, after);

                        &::after {
                            -webkit-animation: spin 1s steps(8) infinite;
                            animation: spin 1s steps(8) infinite;
                        }
                    }

                    .msc-add-order-template-to-cart__dialog__process-status {
                        color: var(--msv-accent-brand-color);
                    }
                }

                &__success {
                    .msc-add-order-template-to-cart__dialog__image {
                        @include add-icon($msv-Checkbox-Checked, after);
                        color: var(-msv-success-color);
                    }

                    .msc-add-order-template-to-cart__dialog__process-status {
                        color: var(-msv-success-color);
                    }
                }

                &__failed {
                    .msc-add-order-template-to-cart__dialog__image {
                        @include add-icon($msv-Error, after);
                        color: var(--msv-error-color);
                    }

                    .msc-add-order-template-to-cart__dialog__process-status {
                        color: var(--msv-error-color);
                    }
                }
            }

            &__header {
                margin-bottom: $msv-dialog-modal-block-margin-side;

                .msc-modal__title {
                    @include font-content-l();

                    span {
                        &:nth-child(1) {
                            font-weight: var(--msv-font-weight-bold);
                        }
                    }
                }
            }
        }
    }
}

.ms-order-template{
    & .msc-add-line-to-template__button,
    & .add-selected-to-bag, 
    & .remove-selected {
        font-size: 14px;
        line-height: 19px;
        padding: 0;
        height: auto;
    }
}

.ms-order-template__content{
    margin-bottom: 80px;
}
.ms-order-template-table-line .customerContextualPrice {
    display: flex;
    
    & .msc-price__actual{
        font-size: 14px;
    }
}

.warningCheckbox label{
    display: flex;
    align-items: center;

    & input{
        margin-right: 10px;
        cursor: pointer;
    }
}

.cta-quotation-warning-ok{
    @include primary-button();
    margin-right: 20px;
}

.cta-quotation-warning-cancel{
    @include secondary-button();
}