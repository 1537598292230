.ms-buybox{
    .msc-add-to-order-template-icon {
        //@include add-icon($msv-file, after, $msv-outline-icon-weight);
        // @include vfi();

        // &__added {
        //     @include add-icon($msv-file, after);
        // }

        // &:not(:disabled) {
        //     &:hover {
        //         cursor: pointer;
        //     }
        // }
        @include primary-button();
        width: 100%;
        cursor: pointer;
        // text-decoration: underline;
        // background: transparent;
        // border: none;
        // font-size: 1.5rem;
        margin-bottom: 1rem;
        //color: var(--tebu-black);
    }
}
