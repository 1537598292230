@import "../00-settings/colors";
@import "../00-settings/font";
@import "../00-settings/responsive-breakpoints";

$msv-btn-min-width: 160px;
$msv-btn-side-padding: 5rem;
$msv-btn-vertical-padding: 1.6rem;
$btn-border-radius: 0.5rem;

:root {
    // Background
    --msv-secondary-button-background-color: #{$msv-white};
}

@mixin button-link($color: $msv-link-color) {
    border-color: transparent;
    background-color: transparent;
    color: $color;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

@mixin primary-button($bg-color: var(--tebu-blue),
$color: var(--tebu-green), $border-color: var(--tebu-blue)) {
    background-color: $bg-color;
    border: none;
    border-radius: $btn-border-radius;
    color: $color;
    padding: $msv-btn-vertical-padding $msv-btn-side-padding;
    min-width: $msv-btn-min-width;
    position: relative;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 400;
    text-decoration: none;
    font-family: 'Poppins-SemiBold', sans-serif;
    text-align: center;

    &:hover,
    &:focus {
        color: var(--tebu-blue);
        text-decoration: none;
    }

    &:disabled,
    &[disabled] {
        cursor: default;
        background-color: #9FAFDF; //var(--tebu-disabled-blue);
        color: #99F5E5;//var(--tebu-disabled-green);
        //border: 1px solid var(--tebu-black);
    }

    &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: var(--tebu-green);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition: -webkit-transform 0.5s ease-out;
        transition: -webkit-transform 0.5s ease-out;
        -o-transition: transform 0.5s ease-out;
        transition: transform 0.5s ease-out;
        transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
        border-radius: $btn-border-radius;
    }

    &:disabled:hover::before,
    &:disabled:focus::before,
    &[disabled]:hover::before,
    &[disabled]:focus::before{
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }

    &:hover::before,
    &:focus::before{
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

@mixin primary-small-button($bg-color: var(--tebu-blue),
$color: var(--tebu-green), $border-color: var(--tebu-blue)) {
    background-color: $bg-color;
    border: none;
    border-radius: $btn-border-radius;
    color: $color;
    padding: 1.1rem 3.8rem;
    // min-width: $msv-btn-min-width;
    position: relative;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 400;
    text-decoration: none;
    font-family: 'Poppins-SemiBold', sans-serif;
    text-align: center;

    &:hover,
    &:focus {
        color: var(--tebu-blue);
        text-decoration: none;
    }

    &:disabled,
    &[disabled] {
        cursor: default;
        background-color: #9FAFDF; //var(--tebu-disabled-blue);
        color: #99F5E5;//var(--tebu-disabled-green);
        //border: 1px solid var(--tebu-black);
    }

    &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: var(--tebu-green);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition: -webkit-transform 0.5s ease-out;
        transition: -webkit-transform 0.5s ease-out;
        -o-transition: transform 0.5s ease-out;
        transition: transform 0.5s ease-out;
        transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
        border-radius: $btn-border-radius;
    }

    &:disabled:hover::before,
    &:disabled:focus::before,
    &[disabled]:hover::before,
    &[disabled]:focus::before{
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }

    &:hover::before,
    &:focus::before{
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

@mixin secondary-button($bg-color: var(--msv-font-secondary-color),
$color: var(--tebu-blue), $border-color: var(--tebu-blue), $disableBtn: true) {
    background-color: #fff; //$bg-color;
    border: 1px solid var(--tebu-blue); //$border-color;
    border-radius: $btn-border-radius;
    color: var(--tebu-blue); //$color;
    padding: 1.4rem $msv-btn-side-padding;
    min-width: $msv-btn-min-width;
    position: relative;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 400;
    text-decoration: none;
    font-family: 'Poppins-SemiBold', sans-serif;
    text-align: center; 

    &:hover,
    &:focus {
        color: #fff;
        text-decoration: none;
    }

    @if $disableBtn {
        &:disabled,
        &[disabled] {
            cursor: default;
            border-color: var(--tebu-disabled-blue);
            color: var(--tebu-disabled-blue);
        }
    }

    &::before{
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        z-index: -1;
        background: var(--tebu-blue);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition: -webkit-transform 0.5s ease-out;
        transition: -webkit-transform 0.5s ease-out;
        -o-transition: transform 0.5s ease-out;
        transition: transform 0.5s ease-out;
        transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
        border-radius: $btn-border-radius;
    }


    &:disabled:hover::before,
    &:disabled:focus::before,
    &[disabled]:hover::before,
    &[disabled]:focus::before{
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }

    &:hover::before,
    &:focus::before{
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

@mixin button-default() {
    @include vfi();
    @include font-content-l();
    cursor: pointer;
    padding: 12px 20px;
    height: 48px;
    min-width: 160px;
    text-align: center;
}

@mixin button-icon {
    width: 48px;
    height: 48px;
}
