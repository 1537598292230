$msv-wishlist-items-width: 100%; //310px;
$msv-wishlist-items-width-m: 100%; //310px;
$msv-wishlist-items-empty-image-height: 310;
$msv-wishlist-items-heading-spacing: 20px;
$msv-wishlist-item-margin: 0 20px 60px 0;
$msv-wishlist-item-height: 468px;
$msv-wishlist-item-margin-mobile: 0 20px 60px 0;
$msv-wishlist-item-image-margin-bottom: 20px;
$msv-wishlist-item-image-size: $msv-wishlist-items-width;
$msv-wishlist-item-image-width-m: $msv-wishlist-items-width-m;
$msv-wishlist-item-price-original-font-color: $msv-gray-300;
$msv-wishlist-item-price-original-line-height: 16px;
$msv-wishlist-item-price-current-font-color: var(--msv-font-primary-color);
$msv-wishlist-item-dimension-font-size: 12px;
$msv-wishlist-item-dimension-line-height: 16px;
$msv-wishlist-item-dimension-margin-right: 10px;
$msv-wishlist-item-dimension-label-margin: 5px;
$msv-wishlist-item-add-button-icon-margin-right: 8px;
$msv-wishlist-item-add-button-margin: 20px auto 0 0;
$msv-wishlist-item-remove-button-right: 0;
$msv-wishlist-item-remove-button-padding: 6px 12px;
$msv-wishlist-item-message-padding: 10px;
$msv-wishlist-item-product-status-max-width: 310px;
$msv-wishlist-items-message-empty-margin-bottom: 32px;

//style presets
:root {
    --msv-wishlist-font-size: var(--msv-body-font-size-s);
    --msv-wishlist-title-font-color: var(--msv-font-primary-color);

    // heading
    --msv-wishlist-heading-font-size: var(--msv-body-font-size-l);
    --msv-wishlist-heading-font-color: var(--msv-font-primary-color);

    // error
    --msv-wishlist-error-bg: var(--msv-error-color);
    --msv-wishlist-error-border: var(--msv-error-color);
    --msv-wishlist-error-font-color: var(--msv-font-secondary-color);

    // link
    --msv-wishlist-link-color: var(--msv-font-primary-color);

    // button
    --msv-wishlist-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-wishlist-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-wishlist-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-wishlist-items {
    @include font-content(var(--msv-font-weight-normal), var(--msv-wishlist-font-size), $msv-line-height-m);

    &__heading {
        // @include font-content(var(--msv-font-weight-normal),
        // var(--msv-wishlist-heading-font-size), $msv-line-height-xl);
        // color: var(--msv-wishlist-heading-font-color);
        // margin-bottom: $msv-wishlist-items-heading-spacing;
    }

    .msc_image{
        display: none !important
    }

    &__message {
        @include font-content-l();
        padding: $msv-wishlist-item-message-padding;

        &-failed {
            color: var(--msv-wishlist-error-font-color);
            background-color: var(--msv-wishlist-error-bg);
            border: 1px solid var(--msv-wishlist-error-border);
        }

        &-empty {
            color: var(--msv-wishlist-title-font-color);
        }
    }

    &__products {
        // display: flex;
        // flex-wrap: wrap;
        padding-top: 2rem !important;//$msv-wishlist-items-heading-spacing;
        display: block;
        margin-bottom: 4rem !important;    
    }

    &__product {
        display: flex;
        flex-wrap: wrap;
        margin: $msv-wishlist-item-margin;
        //min-height: $msv-wishlist-item-height;
        position: relative;
        border-bottom: 1px solid #d1d1d1;
        margin: 0 0 10px 0;
        padding-bottom: 20px;

        //@include image($msv-wishlist-item-image-size);

        &-image {
            margin-bottom: $msv-wishlist-item-image-margin-bottom;
        }

        &-link {
            @include button-link(var(--msv-wishlist-link-color));
            @include font-content(var(--msv-font-weight-normal), var(--msv-wishlist-font-size), $msv-line-height-m);
            @include vfi();
            width: $msv-wishlist-items-width;
            font-family: 'Poppins-semibold';
            //min-height: 80px;
        }

        &-price {
            @include font-content-m();

            .msc-price__strikethrough {
                font-weight: var(--msv-font-weight-light);
                font-size: 1.4rem;
                line-height: 2.2rem;
                color: #676967;
                margin-right: 1.2rem;
                padding-bottom: 2px;
                font-weight: 400;
            }

            .msc-price__actual {
                font-weight: var(--msv-font-weight-bold);
                color: var(--msv-wishlist-title-font-color);
            }

            .price-strikethrough {
                color: $msv-wishlist-item-price-original-font-color;
                line-height: $msv-wishlist-item-price-original-line-height;
                font-size: 1.4rem;
                line-height: 2.2rem;
                color: #676967;
                margin-right: 1.2rem;
                padding-bottom: 2px;
                font-weight: 400;
            }

            .price-actual {
                color: $msv-wishlist-item-price-current-font-color;
            }

            // not needed when Price component is moved to utilities folder
            .price-text-screen-reader {
                display: none;
            }
        }

        &-dimensions {
            color: var(--msv-wishlist-title-font-color);
            display: flex;
            width: 100%;
        }

        &-dimension {
            margin-right: $msv-wishlist-item-dimension-margin-right;

            .msc-wishlist-dimension {
                &__label {
                    margin-right: $msv-wishlist-item-dimension-label-margin;
                }
            }
        }

        &-add-button {
            @include secondary-button(var(--msv-wishlist-secondary-btn-bg),
            var(--msv-wishlist-secondary-btn-font-color), var(--msv-wishlist-secondary-btn-border));
            box-sizing: border-box;
            font-size: var(--msv-wishlist-font-size);
            // margin: $msv-wishlist-item-add-button-margin;
            margin: 0;
            margin-top: -40px;
            margin-left: calc(100% - 230px);

            @media screen and (max-width: 640px) {
                margin-top: 0px;
            }
        }

        &-remove-button {
            @include vfi();
            @include add-icon($msv-x-shape);
            background-color: transparent;
            border: 1px solid transparent;
            color: var(--msv-font-primary-color);
            padding: $msv-wishlist-item-remove-button-padding;
            position: absolute;
            right: $msv-wishlist-item-remove-button-right;
            cursor: pointer;
        }

        &-status {
            max-width: $msv-wishlist-item-product-status-max-width;

            .msc-alert {
                button.close {
                    background-color: transparent;
                    border: none;
                    float: right;
                }
            }
        }

        .entire-product-link {
            display: block;
            overflow: hidden;

            &:hover {
                text-decoration: none;

                .ms-wishlist-items__product-title {
                    text-decoration: underline;
                }
            }
        }
    }

    &__inventory-label {
        color: var(--msv-wishlist-link-color);
    }

    &__message-empty,
    &__message-waiting,
    &__message-failed {
        margin-bottom: $msv-wishlist-items-message-empty-margin-bottom;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__product {
            flex: 0 1 $msv-wishlist-items-width-m;
            left: 50%;
            margin: $msv-wishlist-item-margin-mobile;
            min-height: 0;
            transform: translate(-50%, 0);
            width: $msv-wishlist-items-width;

            &-image {
                width: $msv-wishlist-item-image-width-m;
            }
        }

        @include image($msv-wishlist-items-empty-image-height);
    }

    @include image($msv-wishlist-items-empty-image-height);
}

.ms-wishlist-items__product-price {
    font-size: 1.5rem;
    line-height: 2.6rem;
    font-family: 'Poppins';
    display: flex;
    width: 100%;

    & .msc-price__actual{
        font-weight: 400 !important;
    }

}


.whishlist-item-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.whislist-item-details{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.whislist-item-infos{
    display: flex;

    .ms-wishlist-items__product-price {
        font-size: 1.5rem;
        line-height: 2.6rem;
        font-family: 'Poppins';
        display: flex;

        & .msc-price__actual{
            font-weight: 400 !important;
        }

        &::before{
            content: '|';
            padding: 0 5px 0 0;
        }
    }
}
.whislist-cta {
    .ms-wishlist-items__product-add-button{
        margin: 0 !important;
    }
}

.wishlist-item-externalID, .wishlist-item-size-description{
    width: 100%;
}