$msv-alert-success-color: $msv-text-color;
$msv-alert-success-background-color: $msv-gray-300;

$msv-alert-close-button-color: var(--tebu-klein);
$msv-alert-close-button-right: 10px;
$msv-alert-border-radius: 0;
$msv-alert-padding: 0.75rem 1.25rem;
$msv-alert-margin-bottom: 1rem;
$msv-alert-border: 1px solid transparent;

$msv-alert-icon: $msv-IncidentTriangle;
$msv-alert-icon-margin: 8px;
$msv-alert-line-margin-top: 8px;

.msc-alert {
    @include font-content-m(600);
    border: $msv-alert-border;
    border-radius: $msv-alert-border-radius;
    display: block;
    margin-bottom: $msv-alert-margin-bottom;
    padding: $msv-alert-padding;
    position: relative;
    text-transform: none;

    &::before {
        color: $msv-alert-close-button-color;
        position: absolute;
        right: $msv-alert-close-button-right;
    }

    .msi-exclamation-triangle {
        margin-right: $msv-alert-icon-margin;

        @include add-icon($msv-alert-icon, before);
    }

    .msc-alert__line {
        margin-top: $msv-alert-line-margin-top;
    }

    .close {
        @include add-icon($msv-x-shape);
        color: var(--tebu-klein);
        cursor: pointer;
    }
}

.msc-alert-success {
    margin-top: 10px;
    color: var(--tebu-klein);
    border-color: transparent;
    border: none;
    background-repeat: no-repeat;
    background-position: left center;
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: 400;
    padding: 1.5rem 1rem 1.5rem 50px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxLjUgMikiIHN0cm9rZT0iIzBGMzdBRiIgc3Ryb2tlLXdpZHRoPSIyIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzIDcpIiBzdHJva2UtbGluZWNhcD0icm91bmQiPjxsaW5lIHgxPSIuNSIgeTE9IjExLjUiIHgyPSIuNSIgeTI9IjEyLjUiLz48bGluZSB4MT0iLjUiIHkxPSIuNSIgeDI9Ii41IiB5Mj0iOC41Ii8+PC9nPjxjaXJjbGUgY3g9IjEzLjUiIGN5PSIxMy41IiByPSIxMy41Ii8+PC9nPjwvc3ZnPg==');
    background-position: 10px center;
    background-color: var(--tebu-light-blue);
    text-align: left;
}

.msc-cart-line__product-quantity {
    .msc-alert__header {
        background-color: transparent;
        color: var(--msv-error-color);
        border-color: transparent;
        padding: 0;

        @include font-content-m(600);
        border: $msv-alert-border;
        border-radius: $msv-alert-border-radius;
        display: block;
        margin-bottom: $msv-alert-margin-bottom;
        position: relative;
        text-transform: none;

        .msi-exclamation-triangle {
            margin-right: $msv-alert-icon-margin;

            @include add-icon($msv-alert-icon, before);
        }
    }
}

.msc-alert-danger {
    margin-top: 10px;
    color: var(--tebu-blue);
    border-color: transparent;
    border: none;
    background-repeat: no-repeat;
    background-position: left center;
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: 400;
    padding: 1.5rem 1rem 1.5rem 50px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEuMDQzMTEwNjIsMjUuNzYzNTA5NCBMMTMuNjkzMjU1MywzLjMxODUzODQyIEMxNC4xMDAwMDc4LDIuNTk2ODQzMjYgMTUuMDE0Nzk1NCwyLjM0MTUzMTc5IDE1LjczNjQ5MDYsMi43NDgyODQzIEMxNS45NzUwNjc5LDIuODgyNzQ4MTkgMTYuMTcyMjgwOCwzLjA3OTk2MTA1IDE2LjMwNjc0NDcsMy4zMTg1Mzg0MiBMMjguOTU2ODg5NCwyNS43NjM1MDk0IEMyOS4zNjM2NDE5LDI2LjQ4NTIwNDYgMjkuMTA4MzMwNCwyNy4zOTk5OTIyIDI4LjM4NjYzNTMsMjcuODA2NzQ0NyBDMjguMTYxODQ2OCwyNy45MzM0MzcgMjcuOTA4MTc3MywyOCAyNy42NTAxNDQ3LDI4IEwyLjM0OTg1NTMsMjggQzEuNTIxNDI4MTgsMjggMC44NDk4NTUzLDI3LjMyODQyNzEgMC44NDk4NTUzLDI2LjUgQzAuODQ5ODU1MywyNi4yNDE5Njc0IDAuOTE2NDE4MjU2LDI1Ljk4ODI5NzkgMS4wNDMxMTA2MiwyNS43NjM1MDk0IFoiIHN0cm9rZT0iIzA3MzNCNiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PGxpbmUgeDE9IjE1IiB5MT0iMTMiIHgyPSIxNSIgeTI9IjE5IiBzdHJva2U9IiMwRjM3QUYiIHN0cm9rZS13aWR0aD0iMiIvPjxsaW5lIHgxPSIxNSIgeTE9IjIyIiB4Mj0iMTUiIHkyPSIyMyIgc3Ryb2tlPSIjMEYzN0FGIiBzdHJva2Utd2lkdGg9IjIiLz48L2c+PC9zdmc+');
    background-position: 10px center;
    background-color: var(--tebu-light-blue);
    text-align: left;

    & .msi-exclamation-triangle{
        display: none;
    }
}

.msc-alert-noborder {
    // background-color: transparent;
    // color: var(--msv-error-color);
    // border-color: transparent;
    // margin-bottom: 0;
    // padding: 0;
}
