$msv-password-reset-verification-width: 40%;
$msv-password-reset-verification-width-m: 80%;
$msv-password-reset-verification-message-margin-top: 20px;
$msv-password-reset-verification-message-padding: 20px;
$msv-password-reset-verification-account-item-margin-top: 20px;
$msv-password-reset-verification-input-margin-top: 4px;
$msv-password-reset-verification-email-verification-buttons-margin-top: 12px;
$msv-password-reset-verification-button-group-spacing: 32px;
$msv-password-reset-verification-button-margin-top: 20px;

//style presets
:root {
    --msv-password-reset-verification-font-size: var(--msv-body-font-size-s);
    --msv-password-reset-verification-font-color: var(--msv-font-primary-color);

    // heading
    --msv-password-reset-verification-heading-font-size: var(--msv-body-font-size-l);
    --msv-password-reset-verification-heading-font-color: var(--msv-font-primary-color);

    //error
    --msv-password-reset-verification-error-font-color: #{$msv-red};
    --msv-password-reset-verification-error-font-size: var(--msv-body-font-size-s);

    //form
    --msv-password-reset-verification-form-input-bg: #{$msv-white};
    --msv-password-reset-verification-form-input-font-color: var(--msv-font-primary-color);
    --msv-password-reset-verification-form-input-border: var(--tebu-grey);

    // alert
    --msv-password-reset-verification-success-alert-bg: #{$msv-gray-300};
    --msv-password-reset-verification-success-alert-font-color: var(--msv-font-primary-color);
    --msv-password-reset-verification-success-alert-border: #{$msv-gray-900};
    --msv-password-reset-verification-error-alert-bg: var(--msv-error-color);
    --msv-password-reset-verification-error-alert-font-color: var(--msv-font-secondary-color);
    --msv-password-reset-verification-error-alert-border: var(--msv-error-color);

    // primary button
    --msv-password-reset-verification-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-password-reset-verification-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-password-reset-verification-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-password-reset-verification-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-password-reset-verification-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-password-reset-verification-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-password-reset-verification {
    @include font-content(var(--msv-font-weight-normal), var(--msv-password-reset-verification-font-size), $msv-line-height-s);
    color: var(--msv-password-reset-verification-font-color);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__container {
        width: $msv-password-reset-verification-width;
    }

    &__heading {
        @include font-content(var(--msv-font-weight-normal), var(--msv-password-reset-verification-heading-font-size), $msv-line-height-l);
        color: var(--msv-password-reset-verification-heading-font-color);
    }

    &__account-item {
        margin-top: $msv-password-reset-verification-account-item-margin-top;

        &-input {
            @include form-input-el();
            border: 1px solid var(--msv-password-reset-verification-form-input-border);
            margin-top: $msv-password-reset-verification-input-margin-top;
            width: 100%;
        }
    }

    &__email-verification-buttons {
        margin-top: $msv-password-reset-verification-email-verification-buttons-margin-top;
    }

    /* Override inline style inject by AAD so we need important */
    &__page-success[aria-hidden='false'],
    &__page-error[aria-hidden='false'] {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        display: block !important;
    }

    &__page-success {
        // @include validation-success(var(--msv-password-reset-verification-success-alert-bg),
        // var(--msv-password-reset-verification-success-alert-border),
        // var(--msv-password-reset-verification-success-alert-font-color));
        display: none;
        margin-top: $msv-password-reset-verification-message-margin-top;
        padding: $msv-password-reset-verification-message-padding;
        color: var(--tebu-klein);
        border-color: transparent;
        border: none;
        background-repeat: no-repeat;
        background-position: left center;
        font-size: 1.4rem;
        line-height: 2.2rem;
        font-weight: 400;
        padding: 1.5rem 1rem 1.5rem 50px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxLjUgMikiIHN0cm9rZT0iIzBGMzdBRiIgc3Ryb2tlLXdpZHRoPSIyIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzIDcpIiBzdHJva2UtbGluZWNhcD0icm91bmQiPjxsaW5lIHgxPSIuNSIgeTE9IjExLjUiIHgyPSIuNSIgeTI9IjEyLjUiLz48bGluZSB4MT0iLjUiIHkxPSIuNSIgeDI9Ii41IiB5Mj0iOC41Ii8+PC9nPjxjaXJjbGUgY3g9IjEzLjUiIGN5PSIxMy41IiByPSIxMy41Ii8+PC9nPjwvc3ZnPg==');
        background-position: 10px center;
        background-color: var(--tebu-light-blue);
        text-align: left;
    }

    &__page-error {
        // @include validation-error(var(--msv-password-reset-verification-error-alert-bg),
        // var(--msv-password-reset-verification-error-alert-border),
        // var(--msv-password-reset-verification-error-alert-font-color));
        display: none;
        margin-top: $msv-password-reset-verification-message-margin-top;
        padding: $msv-password-reset-verification-message-padding;

        color: var(--tebu-klein);
        background-color: transparent;
        padding: 0.5rem 0 0 30px;
        background-image: url('../../../img/croix_bleue.png');
        background-repeat: no-repeat;
        background-position: left center;
        font-size: 1.4rem;
        line-height: 2.2rem;
        font-weight: 400;
    }

    &__item-error[aria-hidden='false'] {
        @include font-content(var(--msv-font-weight-normal), var(--msv-password-reset-verification-error-font-size), $msv-line-height-m);
        color: var(--msv-password-reset-verification-error-font-color);

        color: var(--tebu-klein);
        background-color: transparent;
        padding: 0.5rem 0 0 30px;
        background-image: url('../../../img/croix_bleue.png');
        background-repeat: no-repeat;
        background-position: left center;
        font-size: 1.4rem;
        line-height: 2.2rem;
        font-weight: 400;
    }

    &__email-verification-button,
    &__continue-button {
        @include primary-button(
            var(--msv-password-reset-verification-primary-btn-bg),
            var(--msv-password-reset-verification-primary-btn-font-color),
            var(--msv-password-reset-verification-primary-btn-border)
        );
        margin-top: $msv-password-reset-verification-button-margin-top;
        width: 100%;
    }

    &__cancel-button {
        @include secondary-button(
            var(--msv-password-reset-verification-secondary-btn-bg),
            var(--msv-password-reset-verification-secondary-btn-font-color),
            var(--msv-password-reset-verification-secondary-btn-border)
        );
        margin-top: $msv-password-reset-verification-button-margin-top;
        width: 100%;
        margin-bottom: 2rem;
    }

    /* &__verify-email-verify, */
    &__verify-email-resend,
    &__continue-button:disabled {
        display: none;
    }

    &__verify-email-edit {
        display: none !important;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__container {
            width: $msv-password-reset-verification-width-m;
        }
    }
}

.ms-header__container .ms-header__logo img {
    max-width: 225px;
}
