// bootstrap color variables
$msv-white: #FFFFFF;
$msv-black: #000000;
$msv-blue:    #0062AF;
$msv-green: #107C10;
$msv-red: #A80000;
$msv-pink: #FCDFDF;
$msv-cyan: #0776D1;
$msv-yellow:#FFFF00;
$msv-grey: #323130;

$msv-gray-100: #F8F7F6;
$msv-gray-200: #E8E8E8;
$msv-gray-300: #D1D1D1;
$msv-gray-400: #6B727A;
$msv-gray-500: #616365;
$msv-gray-800: #8F8F8F;
$msv-gray-900: #1D1D1D;
$msv-gray-1000: #2D2D2D;
$msv-gray-tint: #495057;

$tebu-black: #676967;
$tebu-blue: #0F37AF;
$tebu-green: #00E6BE;
$tebu-klein: #0055FF;
$tebu-light-blue: #F0F0FF;
$tebu-disabled-blue: #9FAFDF;
$tebu-disabled-green: #99F5E5;
$tebu-grey: #D9DBDD;

$msv-primary: $tebu-blue;
$msv-primary-hover: $tebu-green;
$msv-secondary: $tebu-green;
$msv-secondary-hover: $tebu-light-blue;

$msv-text-color: $msv-gray-900;
$msv-link-color: $msv-gray-900;
$msv-divider-color: $msv-gray-300;
$msv-error-color: $msv-red;
$msv-success-color: $msv-green;
$modal-input-background: $msv-gray-100;
$msv-modal-overlay: rgba(0, 0, 0, 0.7);
$msv-box-shadow-color: rgba(0, 0, 0, 0.132);
$msv-selected-user-background-color: rgba(0, 0, 0, 0.04);

$msv-selected-button-background: rgba(0, 0, 0, 0.02);
$msv-selected-hovered-button-background: rgba(0, 0, 0, 0.04);

$msv-flyout-empty-img-border: #F3F2F1;

// Social button color variables
$facebook-bg-color: #3C3C41;
$facebook-hover-color: #1D1D1D;
$microsoft-bg-color: #3C3C41;
$microsoft-hover-color: #1D1D1D;
$social-bg-color: #3C3C41;
$social-hover-color: #1D1D1D;

$msv-unfilled-rating-color: $msv-gray-800;

// Style presets
:root {
    // Background
    --msv-bg-color: #{$msv-white};

    // Font
    --msv-font-primary-color: #{$tebu-black};
    --msv-font-secondary-color: #{$tebu-green};

    // Borders
    --msv-border-color: #{$msv-gray-100};
    --msv-btn-input-border-color: #{$msv-gray-500};

    // Brand accents
    --msv-accent-brand-color: #{$msv-primary};
    --msv-accent-brand-alt-color: #{$msv-primary-hover};
    --msv-accent-secondary-btn: #{$msv-secondary-hover};

    // Status
    --msv-error-color: #{$msv-red};
    --msv-success-color: #{$msv-green};

    // Modal
    --msv-modal-bg-color: #{$msv-white};
    --msv-modal-font-color: var(--msv-font-primary-color);
    --msv-flyout-bg-color: #{$msv-white};

    // Buttons
    --msv-selected-button-background: #{$msv-selected-button-background};
    --msv-selected-hovered-button-background: #{$msv-selected-hovered-button-background};

    //tebu
    --tebu-black: #{$tebu-black};
    --tebu-blue: #{$tebu-blue};
    --tebu-green: #{$tebu-green};
    --tebu-klein: #{$tebu-klein};
    --tebu-light-blue: #{$tebu-light-blue};
    --tebu-disabled-blue: #{$tebu-disabled-blue};
    --tebu-disabled-green: #{$tebu-disabled-green};
    --tebu-grey: #{$tebu-grey};
}

// Gradients
.bg-blue-gradient {
    background-image: -webkit-gradient(linear, left top, right top, from(#0F37AF), to(#00E6BE));
    background-image: -o-linear-gradient(left, #0F37AF, #00E6BE);
    background-image: linear-gradient(to right, #0F37AF, #00E6BE);
}

.bg-green-blue-gradient {
    background: -webkit-gradient(linear, left top, right top, from(#00E6BE), to(#0082F5));
    background: -o-linear-gradient(left, #00E6BE, #0082F5);
    background: linear-gradient(to right, #00E6BE, #0082F5);
}
.bg-green-blue-gradient.\--left {
    background: -webkit-gradient(linear, right top, left top, from(#00E6BE), to(#0082F5));
    background: -o-linear-gradient(right, #00E6BE, #0082F5);
    background: linear-gradient(to left, #00E6BE, #0082F5);
}
.bg-green-blue-gradient.\--180 {
    background: -webkit-gradient(linear, left top, left bottom, from(#00E6BE), to(#0082F5));
    background: -o-linear-gradient(top, #00E6BE, #0082F5);
    background: linear-gradient(180deg, #00E6BE, #0082F5);
}

.bg-blue-green-gradient {
    background: -webkit-gradient(linear, left top, right top, from(#0082F5), to(#00E6BE));
    background: -o-linear-gradient(left, #0082F5, #00E6BE);
    background: linear-gradient(to right, #0082F5, #00E6BE);
}

.bg-orange-gradient {
    background-image: -webkit-gradient(linear, left top, right top, from(#FF8C64), to(#FFE67D));
    background-image: -o-linear-gradient(left, #FF8C64, #FFE67D);
    background-image: linear-gradient(to right, #FF8C64, #FFE67D);
}

.bg-purple-gradient {
    background-image: -webkit-gradient(linear, left top, right top, from(#00AFFF), to(#A55FFA));
    background-image: -o-linear-gradient(left, #00AFFF, #A55FFA);
    background-image: linear-gradient(to right, #00AFFF, #A55FFA);
}

.bg-purple-blue-gradient {
    background: -webkit-gradient(linear, right top, left top, from(#A55FFA), to(#0082F5));
    background: -o-linear-gradient(right, #A55FFA 0%, #0082F5 100%);
    background: linear-gradient(to left, #A55FFA 0%, #0082F5 100%);
}
.bg-purple-blue-gradient.\--180 {
    background: -webkit-gradient(linear, left top, left bottom, from(#A55FFA), to(#0082F5));
    background: -o-linear-gradient(top, #A55FFA 0%, #0082F5 100%);
    background: linear-gradient(180deg, #A55FFA 0%, #0082F5 100%);
}

.bg-green-gradient {
    background-image: -webkit-gradient(linear, left top, right top, from(#00E6BE), to(#FFE67D));
    background-image: -o-linear-gradient(left, #00E6BE, #FFE67D);
    background-image: linear-gradient(to right, #00E6BE, #FFE67D);
}

.bg-blue-gradient-top {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#00E6BE), to(#0F37AF));
    background-image: -o-linear-gradient(bottom, #00E6BE, #0F37AF);
    background-image: linear-gradient(to top, #00E6BE, #0F37AF);
}

.bg-orange-gradient-top {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#FF8C64), to(#FFE67D));
    background-image: -o-linear-gradient(bottom, #FF8C64, #FFE67D);
    background-image: linear-gradient(to top, #FF8C64, #FFE67D);
}

.bg-purple-gradient-top {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#A55FFA), to(#00AFFF));
    background-image: -o-linear-gradient(bottom, #A55FFA, #00AFFF);
    background-image: linear-gradient(to top, #A55FFA, #00AFFF);
}

.bg-green-gradient-top {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#00E6BE), to(#FFE67D));
    background-image: -o-linear-gradient(bottom, #00E6BE, #FFE67D);
    background-image: linear-gradient(to top, #00E6BE, #FFE67D);
}