@import 'notifications/notifications-lists/global-notifications-list';
@import 'notifications/base-notification';
@import 'notifications/system-notifications';
@import 'aad-generic';
@import 'accordion';
@import 'account-profile-edit';
@import 'account-profile';
@import 'account-landing';
@import 'account-loyalty';
@import 'account-balance';
@import 'address';
@import 'b2b-requests';
@import 'breadcrumb';
@import 'business-organization-list';
@import 'business-sign-up';
@import 'buybox';
@import 'carousel';
@import 'cart';
@import 'check-in-for-pickup';
@import 'checkout-billing-address';
@import 'checkout-customer-account';
@import 'checkout-delivery-options';
@import 'checkout-gift-card.scss';
@import 'checkout-guest-profile';
@import 'checkout-loyalty.scss';
@import 'checkout-payment-instrument.scss';
@import 'checkout-pickup.scss';
@import 'checkout-shipping-address';
@import 'checkout-terms-and-conditions.scss';
@import 'checkout';
@import 'cookie-compliance';
@import 'content-block-full-width';
@import 'content-block-left-right';
@import 'content-block-tile';
@import 'footer-category';
@import 'form-builder';
@import 'gift-card-balance-check';
@import 'header';
@import 'hero';
@import 'iframe';
@import 'invoice-details';
@import 'invoices-list';
@import 'loyalty-signup';
@import 'loyalty-terms';
@import 'media-gallery';
@import 'mini-cart.scss';
@import 'navigation-menu';
@import 'order-confirmation';
@import 'order-details';
@import 'order-history';
@import 'order-summary';
@import 'invoice-summary';
@import 'order-template';
@import 'password-reset-verification';
@import 'password-reset';
@import 'product-collection';
@import 'product-specification';
@import 'promo-banner';
@import 'ratings-histogram';
@import 'refine-menu';
@import 'reviews-list';
@import 'search';
@import 'search-result-container';
@import 'sign-in';
@import 'sign-up';
@import 'site-picker';
@import 'social-share';
@import 'store-locator';
@import 'store-selector';
@import 'tab';
@import 'text-block';
@import 'video-player';
@import 'wishlist';
@import 'write-review';
@import 'quick-view';
@import 'quick-order';
@import 'blog';

.product-quotation-form {
    width: 100%;
    height: 100%;

    & h2 {
        margin-top: 2rem;
    }

    & .product-ref {
        text-align: center;
        font-size: 1.4rem;
    }

    & iframe {
        width: 100%;
        height: 100%;
    }
}

button.close-modal-button.msc-btn {
    background-color: var(--tebu-blue);
    border: none;
    border-radius: 0.5rem;
    color: var(--tebu-green);
    padding: 1.6rem 5rem;
    min-width: 160px;
    position: relative;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    font-size: 1.5rem;
    line-height: 1.8rem;
    cursor: pointer;

    &:hover,
    &:focus {
        color: var(--tebu-blue);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: var(--tebu-green);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition: -webkit-transform 0.5s ease-out;
        transition: -webkit-transform 0.5s ease-out;
        -o-transition: transform 0.5s ease-out;
        transition: transform 0.5s ease-out;
        transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
        border-radius: 0.5rem;
    }

    &:hover::before,
    &:focus::before {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

.commerce-cookie-consent-accept,
.commerce-cookie-consent-reject {
    display: none;
}
