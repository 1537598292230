$cartline-lines-item-margin: 20px 0;
$cart-cartline-item-padding-bottom: 20px;
$cart-cartline-remove-button-margin: 20px;
$cart-cartline-product-width: 300px;
$cart-cartline-product-image-margin-right: 20px;
$cart-cartline-product-variant-name-padding-left: 5px;
$cartline-item-bopis-container-margin: 12px 0;
$cart-cart-line-bopis-container-line-height: 20px;
$cartline-item-bopis-shipping-margin-bottom: 10px;
$cartline-item-bopis-changestore-padding: 0;
$cartline-item-bopis-fulfillment-store-margin-top: 3px;
$cartline-item-bopis-btn-margin-top: 8px;
$cartline-item-bopis-method-icon-margin-right: 8px;
$cartline-item-discount-price-margin-right: 5px;
$cartline-item-product-savings-margin-left: 0px;
$cartline-item-product-savings-width: 100%;
$cart-cartline-product-quantity-width: 192px;
$cart-cartline-product-quantity-input-width: 51px;
$cart-cartline-product-quantity-spacing: 0px;
$cart-cartline-product-quantity-button-padding: 10px 0;
$cart-cartline-product-quantity-button-width: 40px;
$cart-quantity-input-padding: 13px 8px 9px 8px;
$cart-empty-cart-width: 240px;
$cart-line-padding-top: 10px;
$cart-line-card-height: 220px;
$cart-lines-group-heading-margin-top: 12px;
$cart-lines-group-heading-margin-left: 76px;
$cart-lines-group-fullfillment-store-heading-margin-top: 5px;
$cart-line-item-group-fullfillment-store-padding-top: 5px;
$cart-lines-group-fullfillment-store-margin-bottom: 14px;
$cart-lines-group-heading-margin-left-ct: 5px;

//style presets
:root {
    --msv-cart-border: #{$msv-gray-300};
    --msv-cart-font-color: var(--msv-font-primary-color);
    --msv-cart-font-size: var(--msv-body-font-size-m);
    --msv-cart-linewraper-count-font-size: var(--msv-body-font-size-s);

    // title
    --msv-cart-title-font-color: var(--msv-font-primary-color);
    --msv-cart-title-font-size: var(--msv-body-font-size-l);
    --msv-cart-discount-color: var(--msv-success-color);
    --msv-cart-discount-size: var(--msv-body-font-size-m);

    // link
    --msv-cart-btn-link-color: var(--msv-font-primary-color);
    --msv-cart-btn-link-size: var(--msv-body-font-size-m);
    --msv-cart-label-color: #4a484a; /*var(--msv-font-primary-color);*/
    --msv-cart-label-size: var(--msv-body-font-size-l);

    //button
    --msv-cart-quantity-btn-bg: var(--tebu-blue);
    --msv-cart-quantity-btn-border: var(--msv-btn-input-border-color);
    --msv-cart-quantity-input-bg: #{$msv-white};
    --msv-cart-quantity-input-color: var(--msv-font-primary-color);
    --msv-cart-quantity-input-border: #ced4da;
    --msv-cart-quantity-input-size: var(--msv-body-font-size-l);

    // primary button
    --msv-cart-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-cart-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-cart-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-cart-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-cart-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-cart-secondary-btn-border: var(--msv-accent-brand-color);

    // group heading
    --msv-cart-lines-group-heading-font-color: var(--msv-font-primary-color);
    --msv-cart-lines-group-heading-font-size: var(--msv-body-font-size-l);
}

.msc-cart {
    &-lines-item {
        border-bottom: 1px solid var(--msv-cart-border);
        padding-bottom: $cart-cartline-item-padding-bottom;
        margin-bottom: 1.3rem;
        margin-top: 1rem;
        padding: 1rem 3rem;
        gap: 2rem;
        border: 1px solid #D9DBDD;
        border-radius: 5px;
        position: relative;
    }

    &-lines-item:first-child{
        margin-top: 0
    }

    &-line {
        display: flex;

        &__content {
            display: flex;
            width: 100%;
        }

        &-item {
            &-product-discount {
                @include font-content(var(--msv-font-weight-normal), var(--msv-cart-discount-size), $msv-line-height-m);
                color: var(--msv-cart-discount-color);

                & .customerContextualPrice{
                    display: inline-block;
                }
            }
        }

        &__product {
            //flex: 1 1 $cart-cartline-product-width;
            width: 70%;

            &-inventory-label {
                @include font-content(var(--msv-font-weight-normal), var(--msv-cart-font-size), $msv-line-height-m);
                color: var(--msv-cart-font-color);
                display: block;
            }

            &-image {
                margin-right: $cart-cartline-product-image-margin-right;
            }

            &-variant {
                &-size,
                &-color,
                &-style,
                &-config {
                    font-size: 1.4rem;
                    line-height: 2.2rem;
                    margin-bottom: 1.6rem;
                    font-weight: 700;

                    .name {
                        padding-left: $cart-cartline-product-variant-name-padding-left;
                        color: var(--msv-cart-font-color);
                        font-weight: 400;
                    }
                }
            }
        }

        &__promo-codes {
            display: inline-block;
        }

        &__product-quantity {
            //flex: 0 0 $cart-cartline-product-quantity-width;
            // position: absolute;
            // right: 7rem;
            // bottom: -3rem;

            &-label {                
                color: var(--msv-cart-label-color);
                font-size: 1.4rem;
                line-height: 2.2rem;
                display: none;
            }

            .quantity {
                display: flex;
                align-items: center;
                padding-top: 2rem;

                .quantity-input {
                    @include form-input-el(var(--msv-cart-quantity-input-bg),var(--msv-cart-quantity-input-color));
                    font-size: var(--msv-cart-quantity-input-size);
                    border: 1px solid var(--msv-cart-quantity-input-border);
                    width: $cart-cartline-product-quantity-input-width;
                    display: inline-block;
                    margin-left: $cart-cartline-product-quantity-spacing;
                    margin-right: $cart-cartline-product-quantity-spacing;
                    -moz-appearance: textfield;
                    padding: $cart-quantity-input-padding;
                    height: 38px;
                    border-radius: 0;
                    font-size: 1.8rem;
                    line-height: 2.6rem;
                    color: #676967;

                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                    }
                }

                .increment {
                    @include font-glyph-standard();
                    @include secondary-button(var(--msv-cart-secondary-btn-bg),
                    var(--msv-cart-secondary-btn-font-color), var(--msv-cart-secondary-btn-border), false);
                    @include add-icon($msv-Add, after);
                    padding: $cart-cartline-product-quantity-button-padding;
                    background: var(--msv-cart-quantity-btn-bg);
                    border-color: var(--msv-cart-quantity-btn-border);
                    border:none;
                    min-width: auto;
                    width: $cart-cartline-product-quantity-button-width;
                    color: #fff;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    cursor: pointer;
                    font-size: 0.8rem;

                    &.transition {
                        background: var(--msv-cart-quantity-btn-bg);
                    }

                    &.disabled {
                        cursor: default;
                        // border-color: $msv-gray-300;
                        // background-color: $msv-gray-300;
                        // color: var(--msv-font-secondary-color);
                    }
                }

                .decrement {
                    @include font-glyph-standard();
                    @include secondary-button(var(--msv-cart-secondary-btn-bg),
                    var(--msv-cart-secondary-btn-font-color), var(--msv-cart-secondary-btn-border), false);
                    @include add-icon($msv-Remove, after);
                    padding: $cart-cartline-product-quantity-button-padding;
                    background: var(--msv-cart-quantity-btn-bg);
                    border-color: var(--msv-cart-quantity-btn-border);
                    border: none;
                    min-width: auto;
                    width: $cart-cartline-product-quantity-button-width;
                    color: #fff;
                    border-radius: 0.5rem;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    cursor: pointer;
                    font-size: 0.8rem;

                    &.transition {
                        background: var(--msv-cart-quantity-btn-bg);
                    }

                    &.disabled {
                        cursor: default;
                        // border-color: $msv-gray-300;
                        // background-color: $msv-gray-300;
                        // color: var(--msv-font-secondary-color);
                    }
                }
            }
        }

        &__product-price,
        &__product-savings {
            @include font-content(var(--msv-font-weight-normal), var(--msv-cart-discount-size), $msv-line-height-m);
            color: var(--msv-cart-discount-color);
            text-align: right;
            margin-left: $cartline-item-product-savings-margin-left;
            width: $cartline-item-product-savings-width;

            .msc-price {
                &__actual {
                    line-height: 2.8rem !important;
                    font-size: 2rem !important;
                }

                &.discount-value {
                    .msc-price__strikethrough {
                        @include font-content-m-strikethrough();
                        display: inline;
                        margin-right: $cartline-item-discount-price-margin-right;
                        font-size: 1.4rem;
                        line-height: 2.2rem;
                        color: #676967;
                        margin-right: 1.2rem;
                        padding-bottom: 2px;
                        font-weight: 400;
                    }
                }
            }

            &-actual {
                .msc-price {
                    &__actual {
                        @include font-content(var(--msv-font-weight-bold),
                        var(--msv-cart-label-size), $msv-line-height-l);
                        display: block;
                        color: var(--msv-cart-label-color);
                    }

                    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                    &__strikethrough {
                        color: var(--msv-cart-font-color);
                        display: none;
                    }
                }
            }

            & .msc-cart-line__product-savings-label,
            & .msc-cart-line__product-savings-text{
                display: none;
            }
        }

        &__actions{
            width: 200px ;
        }

        // hide extra price component
        &__product-price {
            display: none;
        }

        &__product-title {
            //@include font-content(var(--msv-font-weight-bold), var(--msv-cart-title-font-size),$msv-line-height-l);
            //@include vfi();
            color: var(--tebu-blue);
            font-size: 2rem;
            line-height: 2.8rem;
            margin-bottom: 0.5rem;
            font-weight: 700;
        }

        &__product-variants,
        &__other-charges-label,
        &__freight-label,
        .shipping-value,
        .other-charge-value {
            @include font-content(var(--msv-font-weight-normal), var(--msv-cart-font-size), $msv-line-height-m);
            color: var(--msv-cart-font-color);
        }

        &__other-charges-label,
        &__freight-label {
            margin-right: $cart-cartline-product-variant-name-padding-left;
        }

        &__remove-item,
        &__add-to-order-template,
        &__add-to-wishlist {
            @include font-content(var(--msv-font-weight-light), var(--msv-cart-btn-link-size), $msv-line-height-m);
            @include button-link(var(--msv-cart-btn-link-color));
            @include vfi();
            margin-right: $cart-cartline-remove-button-margin;
            padding: 0;
            text-decoration: underline;
        }
        &__add-to-order-template{
            @include add-icon($msv-file, before, $msv-outline-icon-weight);
            text-decoration: underline !important;
            display:block;

            &:hover{
                color: var(--tebu-black);
            }
        }

        &__add-to-wishlist{
            @include add-icon($msv-Heart, before, $msv-outline-icon-weight);
            text-decoration: underline !important;
            display:block;
        
            &__removing {
                @include add-icon($msv-HeartFill, before);
            }
        }

        &__add-to-wishlist{
            text-decoration: underline;
        }

        &__remove-item{
            font-size: 1.2rem;
            line-height: 1.8rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;
            // position: absolute;
            // top: -62px;
            // right: 0;
            margin: 0;
            color: transparent;

            &::after{
                content: "";
                width: 3rem;
                height: 3rem;
                display: block;
                background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik00LjU4NjIwNjksOCBMMjQuNDEzNzkzMSw4IEwyNC40MTM3OTMxLDI1IEMyNC40MTM3OTMxLDI2LjY1Njg1NDIgMjMuMDcwNjQ3NCwyOCAyMS40MTM3OTMxLDI4IEw3LjU4NjIwNjksMjggQzUuOTI5MzUyNjUsMjggNC41ODYyMDY5LDI2LjY1Njg1NDIgNC41ODYyMDY5LDI1IEw0LjU4NjIwNjksOCBMNC41ODYyMDY5LDggWiIgc3Ryb2tlPSIjMEYzN0FGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjxwYXRoIGQ9Ik0yMC4xMDM0NDgzLDkgQzIwLjEwMzQ0ODMsNS42ODYyOTE1IDE3LjU5NDY5OSwzIDE0LjUsMyBDMTEuNDA1MzAxLDMgOC44OTY1NTE3Miw1LjY4NjI5MTUgOC44OTY1NTE3Miw5IiBzdHJva2U9IiMwRjM3QUYiIHN0cm9rZS13aWR0aD0iMiIvPjxsaW5lIHgxPSIyIiB5MT0iOCIgeDI9IjI3IiB5Mj0iOCIgc3Ryb2tlPSIjMEYzN0FGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjxsaW5lIHgxPSIxMSIgeTE9IjEzIiB4Mj0iMTEiIHkyPSIyMyIgc3Ryb2tlPSIjMEYzN0FGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjxsaW5lIHgxPSIxOCIgeTE9IjEzIiB4Mj0iMTgiIHkyPSIyMyIgc3Ryb2tlPSIjMEYzN0FGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvZz48L3N2Zz4=") center center no-repeat;
            }
        }      

        &__add-to-order-template.disabled {
            color: $msv-gray-300;
        }

        &__bopis-container {
            @include font-content(var(--msv-font-weight-normal), var(--msv-cart-font-size), $msv-line-height-m);
            margin: $cartline-item-bopis-container-margin;
        }

        &__bopis-method {
            @include font-content(var(--msv-font-weight-normal), var(--msv-cart-label-size), $msv-line-height-l);

            .ship {
                @include add-icon($msv-StoreAlt, before);
                color: var(--msv-cart-label-color);

                &::before {
                    margin-right: $cartline-item-bopis-method-icon-margin-right;
                }
            }

            .pick-up {
                @include add-icon($msv-DeliveryTruck, before);

                &::before {
                    margin-right: $cartline-item-bopis-method-icon-margin-right;
                }
            }
        }

        &__bopis-shipping {
            align-self: baseline;
            margin-bottom: $cartline-item-bopis-shipping-margin-bottom;

            input {
                @include vfi();
            }
        }

        &__bopis-store-pickup {
            align-self: baseline;
        }

        &__bopis-changestore {
            @include button-link(var(--msv-cart-btn-link-color));
            @include font-content(var(--msv-font-weight-light), var(--msv-cart-btn-link-size), $msv-line-height-m);
            display: block;
            height: auto;
            margin-top: $cartline-item-bopis-btn-margin-top;
            padding: $cartline-item-bopis-changestore-padding;
            text-decoration-line: underline;
        }

        &__bopis-fullfilment {
            &-store {
                margin-top: $cartline-item-bopis-fulfillment-store-margin-top;

                @include font-content(var(--msv-font-weight-bold), var(--msv-cart-font-size), $msv-line-height-m);

                color: var(--msv-cart-font-color);
            }
        }

        &__bopis-btn {
            @include secondary-button(var(--msv-cart-secondary-btn-bg),
            var(--msv-cart-secondary-btn-font-color), var(--msv-cart-secondary-btn-border));
            margin-top: $cartline-item-bopis-btn-margin-top;
        }
    }

    &__empty-cart {
        .msc-cart-line {
            @include font-content(var(--msv-font-weight-light), var(--msv-cart-font-size), $msv-line-height-m);
            padding-top: $cart-line-padding-top;
        }

        width: $cart-empty-cart-width;

        .msc-cart__btn-backtoshopping {
            @include primary-button(var(--msv-cart-primary-btn-bg),
            var(--msv-cart-primary-btn-font-color), var(--msv-cart-primary-btn-border));
        }
    }

    &-lines-group {
        &-wraper {
            &__bopis {
                &-heading {  //msc-cart-lines-group-wraper__bopis-method
                    //border-bottom: 1px solid var(--msv-cart-border);
                    position: relative;
                    top: 10px;
                    padding-bottom: 5px;
                    display: none;

                    &-title {
                        display: flex;
                        flex: 1 1 100px;
                        margin-left: 40px; //$cart-lines-group-heading-margin-left;
                        margin-bottom: $cart-lines-group-fullfillment-store-margin-bottom;

                        &-st {
                            @include font-content(var(--msv-font-weight-bold),
                            var(--msv-cart-font-size), $msv-line-height-m);
                            color: var(--msv-cart-font-color);
                            padding-top: $cart-line-item-group-fullfillment-store-padding-top;
                        }

                        &-ct {
                            @include font-content(var(--msv-font-weight-light),
                            var(--msv-cart-linewraper-count-font-size), $msv-line-height-m);
                            color: var(--msv-cart-font-color);
                            padding-top: $cart-line-item-group-fullfillment-store-padding-top;
                            margin-left: $cart-lines-group-heading-margin-left-ct;
                        }
                    }

                    &-pickup-icon {
                        @include add-icon($msv-cube, before);
                        position: absolute;
                        justify-content: center;
                        font-size: 28px;
                        padding-top: 10px;
                        padding-left: 20px;
                    }

                    &-shipping-icon,
                    &-email-icon,
                    &-ship-icon {
                        @include add-icon($msv-DeliveryTruck, before);
                        position: absolute;
                        justify-content: center;
                        font-size: 28px;
                        // padding-top: 10px;
                        // padding-left: 20px;
                        padding: 0;
                    }

                    &-pickup,
                    &-shipping,
                    &-email,
                    &-ship {
                        color: var(--msv-cart-font-color);
                        margin-top: $cart-lines-group-heading-margin-top;
                        margin-left: $cart-lines-group-heading-margin-left;

                        @include font-content(var(--msv-font-weight-bold), var(--msv-cart-lines-group-heading-font-size),$msv-line-height-l);
                    }
                    &-email{
                        display: none;
                    }
                }

                &-changestore &-btn {
                    @include button-link(var(--msv-cart-btn-link-color));
                    @include font-content(var(--msv-font-weight-light), var(--msv-cart-btn-link-size), $msv-line-height-m);
                    display: block;
                    height: auto;
                    margin-top: $cartline-item-bopis-btn-margin-top;
                    padding: $cartline-item-bopis-changestore-padding;
                    text-decoration-line: underline;
                }
            }
        }
    }
}

.msc-cartline-wraper{
    & .msc-cart-line-top{
        display: flex;
        align-items: flex-start;

        & .msc-cart-line__product-quantity .quantity{
            justify-content: flex-end;
        }

        & .msc-cart-line-top-right .msc-cart-line__remove-item{
            margin-top: 43px;
        }
    }
}
.msc-flyout{
    .msc-cart-line__remove-item{
        justify-content: center;
        position: absolute;
        top: -62px;
        right: 0;
    }
}