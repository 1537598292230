.msc-price {
    &__strikethrough {
        margin-right: 5px;
        text-decoration: line-through;
        font-size: 1.4rem;
        line-height: 2.2rem;
        color: #676967;
        margin-right: 1.2rem;
        padding-bottom: 2px;
        font-weight: 400;
    }

    &.discount-value {
        .msc-price__strikethrough {
            display: block;
            margin-right: 0;
            font-size: 1.4rem;
            line-height: 2.2rem;
            color: #676967;
            margin-right: 1.2rem;
            padding-bottom: 2px;
            font-weight: 400;
        }
    }
}
