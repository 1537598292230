$footer-ms-footer-padding-xl: 48px 0;
$footer-ms-footer-margin-left-mobile: 0;
$footer-category-max-width: 200px;
$footer-category-padding-bottom: 48px;
$footer-item-padding-desktop: 5px 0;
$footer-item-padding-right-mobile: 28px;
$footer-heading-title-line-height: 24px;
$footer-heading-title-margin-bottom: 0.5rem;
$footer-list-item-icon-left: 10px;
$footer-list-item-icon-number: 3;
$footer-list-item-icon-top: 40px;
$footer-row-padding-top-mobile: 32px;
$footer-back-to-top-line-height: 24px;
$footer-back-to-top-height: 48px;
$footer-back-to-top-width: 48px;
$footer-back-to-top-right: 24px;
$footer-back-to-top-bottom: 24px;
$footer-back-to-top-border-radius: 2px;
$footer-categoty-back-to-top-max-width: 0;
$footer-placeholder-image-width: 34px;

:root {
    --msv-footer-bg: var(--tebu-blue);
    // Headings
    --msv-footer-heading-font-color: var(--msv-font-primary-color);
    --msv-footer-heading-font-size: var(--msv-body-font-size-l);

    //Links
    --msv-footer-link-font-color: var(--msv-font-primary-color);
    --msv-footer-link-font-size: var(--msv-body-font-size-m);
}

footer {
    background-color: var(--msv-footer-bg);

    .ms-footer {
        &__list {
            flex: initial;
            width: auto;
        }

        .ms-back-to-top {
            @include vfi();
            background: var(--msv-bg-color);
            border: 1px solid var(--msv-accent-brand-color);
            border-radius: $footer-back-to-top-border-radius;
            bottom: $footer-back-to-top-bottom;
            cursor: pointer;
            display: none;
            height: $footer-back-to-top-height;
            min-width: auto;
            opacity: 0;
            padding: 0;
            position: fixed;
            right: 80px; //$footer-back-to-top-right;
            text-align: center;
            vertical-align: middle;
            width: $footer-back-to-top-width;

            &:hover {
                background: var(--msv-accent-secondary-btn);
            }

            .fa-arrow-up {
                color: var(--msv-accent-brand-color);
                font-size: var(--msv-body-font-size-xl);
                left: 50%;
                line-height: $footer-back-to-top-line-height;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);

                &::before {
                    @include msv-icon();
                    content: $msv-ChevronUp;
                }
            }
        }

        &__item {
            padding: $footer-item-padding-desktop;
        }

        &__heading__title {
            color: #fff;
            font-size: 1.4rem;
            line-height: 2.2rem;
            font-weight: 700;
            margin: 0;
        }

        &__link {
            //@include vfi();
            display: inline-flex;

            @include image($footer-placeholder-image-width);

            img {
                //@include vfi();
            }

            div, &__text {
                color: #fff;
                font-size: 1.4rem;
                line-height: 2.2rem;
                font-weight: 400;
            }
        }

        & > * {
            // justify-content: space-between;
            // margin-left: auto;
            // margin-right: auto;
            // padding: $footer-ms-footer-padding-xl;
            flex-wrap: wrap;
            width: 100%;

            > * {
                width: 100%;
                // max-width: $footer-category-max-width;
                // padding-bottom: $footer-category-padding-bottom;

                // &:nth-child(4) {
                //     .ms-footer__list {
                //         display: flex;
                //         width: auto;
                //     }

                //     .ms-footer__list-item:first-child {
                //         position: absolute;
                //         width: 100%;
                //     }

                //     .ms-footer__list-item:nth-child(1) {
                //         position: absolute;
                //         width: 100%;
                //     }

                //     .ms-footer__list-item:nth-child(2) {
                //         position: relative;
                //         top: $footer-list-item-icon-top;
                //         width: 100%;
                //     }

                //     @for $i from 3 to ($footer-list-item-icon-number + 2) {
                //         .ms-footer__list-item:nth-child(#{$i}) {
                //             left: $footer-list-item-icon-left * ($i - 2);
                //             position: relative;
                //             top: $footer-list-item-icon-top;
                //             width: 100%;
                //         }
                //     }
                // }

                // &:last-child {
                //     max-width: $footer-categoty-back-to-top-max-width;
                // }
            }
        }

        & .top-footer{
            background-color: var(--tebu-light-blue);
            padding: 20px 0;

            & .inner-top-footer > .row{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                & > * {
                    width: 25%;
                    flex: auto;

                    & > * {
                        display: grid;
                        grid-template-columns : 70px calc(100% - 70px);
                        min-height: auto;
                        background-position: left 10px center;

                        & .ms-content-block__details{
                            padding: 0 10px;
                            margin: 10px 0 10px 0;

                            & h3{
                                font-size: 1.4rem;
                                line-height: 2.2rem;
                                color: var(--tebu-blue);
                                font-weight: 700;
                            }
    
                            & div, p{
                                margin: 0;
                                font-size: 1.2rem;
                                line-height: 1.7rem;                                
                            }    
                        }
                
                    }
                }

                @media (max-width: 800px) {
                    & > * {
                        width: 50%;
                    }
                }
            } 
        }

        & .main-footer > .row {
            display: flex;
            padding-top: 4.8rem;
            flex-wrap: wrap;

            & > * {
                // width: 25%;
                // padding: 20px 10px;
                // flex: auto;
                margin: 0;
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 15px;    
                margin-bottom: 2rem;            
            }

            & > .col-12:nth-child(2) ul.ms-footer__list{
                padding-left: 0;
            }

            & > .col-12:nth-child(4) ul.ms-footer__list{
                padding-left: 0;
            }            

            @media (min-width: 768px){
                & > * {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 50%;
                    flex: 0 0 50%;
                    max-width: 50%;
                }
                & > .col-12:nth-child(2) ul.ms-footer__list{
                    padding-left: 2rem;
                }
    
                & > .col-12:nth-child(4) ul.ms-footer__list{
                    padding-left: 2rem;
                }  
            }

            @media (min-width: 992px){    
                & > * {
                    margin-bottom: 0;
                }  

                & > .col-12:nth-child(1) {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 33.33333333%;
                    flex: 0 0 33.33333333%;
                    max-width: 33.33333333%;
                }

                & > .col-12:nth-child(2) {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 25%;
                    flex: 0 0 25%;
                    max-width: 25%;
                }

                & > .col-12:nth-child(3) {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 16.66666667%;
                    flex: 0 0 16.66666667%;
                    max-width: 16.66666667%;
                }

                & > .col-12:nth-child(4) {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 25%;
                    flex: 0 0 25%;
                    max-width: 25%;
                }

                & > .col-12:nth-child(2) ul.ms-footer__list{
                    padding-left: 2.5rem;
                }
    
                & > .col-12:nth-child(4) ul.ms-footer__list{
                    padding-left: 6.7rem;
                }                  
            }           

            // @media (max-width: 800px){
            //     & > * {
            //         width: 50%;
            //         padding: 20px 10px;
            //     }
            // }

            // @media (max-width: 640px){
            //     & > * {
            //         width: 100%;
            //         padding: 20px 10px;
            //     }
            // }
            

            & .footer-logo img{
                //@include image(284px);
                height: auto;
                margin-bottom: 6.7rem;
            }
            & h3 {
                color: #fff;
            }

            & .ms-subscribe h3{
                margin-bottom: 10px;
                font-size: 1.4rem;
                line-height: 2.2rem;
            }
            & .ms-subscribe {
                & .ms-subscribe__details__form{
                    position: relative;

                    @media only screen and (min-width: 768px){
                        & {
                            max-width: 27.8rem;
                        }
                    }                    

                    & input{
                        color: #FFFFFF;
                        font-size: 1.4rem;
                        border: 0.1rem solid #D9DBDD;
                        border-radius: 0.5rem;
                        padding: 1.5rem 2.2rem;
                        height: 5rem;
                        background-color: transparent;
                        outline: none;
                        width: 100%;

                        &::placeholder {
                            color: #FFFFFF;
                        }
                    }
    
                    & button {
                        position: absolute;
                        right: 10px;
                        top: 0;
                        width: 0;
                        overflow: hidden;
                        padding: 0;
                        cursor:auto;

                        @include add-icon($msv-caret-right);

                        background-color: transparent;
                        color: #fff;
                        //padding: 10px 10px;
                        width: 48px;
                        height: 48px;
                        border: none;

                        &:focus{
                            outline: none;
                        }

                        &::before{
                            height: 48px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 25px;
                        }
                    }
                }
            }
        }

        & .bottom-footer{
            margin-top: 40px;
            
            & h3{
                color: #FFFFFF;
                font-size: 14px;
                line-height: 18px;
            }

            & .social-items .row{
                display: flex;
                justify-content: flex-start;

                & > * {
                    flex: none;
                    width: 60px;
                    padding-right: 10px;
                }

                & img{
                    width: 28px;
                    height: 28px;
                }
            }

            & .logistics-items .row{
                display: flex;
                justify-content: flex-end;
                align-items: center;

                & > * {
                    flex: none;
                    padding-right: 30px;
                    display: block;
                    max-width: none;
                    width: auto;
                    padding-bottom: 10px;
                }
                & > *:last-child{
                    padding-right: 0px;
                }

                @media (max-width: 768px){
                    justify-content: flex-start;
                }
            }
        }

        & .copyright {
            max-width: 1140px;
            border-top: 2px solid #d9dbdd;
            padding: 0px 0px 15px 0px;

            & .col-md-6{
                max-width: none;
                flex: auto;
                width: auto;                
            }

            @media only screen and (max-width: 800px){
                & .row{
                    display: block;
                }

                & .col-md-6{
                    padding-left: 15px;
                }
            }

            & .ms-text-block{
                color: #fff;
                margin-top: 17px;
            }

            & .ms-nav__list{
                justify-content: flex-end;
            }

            & .legal-links{
                padding-right: 0;
            }

            & .legal-links .ms-nav {
                background-color: var(--tebu-blue);
                color: #fff;

                & >.ms-nav__list{
                    background-color: var(--tebu-blue);
                }

                & >.ms-nav__list>.ms-nav__list__item {
                    background-color: var(--tebu-blue);
                    color: #fff;
                    margin-right: 4px;
                    
                    &:last-child{
                        margin-right: 0;
                    }
                }

                & a.ms-nav__list__item__link {
                    background-color: var(--tebu-blue);
                    color: #fff;
                    font-size: 1.3rem;
                    line-height: 2.4rem;
                }
                & a.ms-nav__list__item__link:hover{
                    text-decoration: underline;
                }
            }
        }


    }

    @media (max-width: $msv-breakpoint-m) {
        .ms-footer {
            margin-left: $footer-ms-footer-margin-left-mobile;

            &__item {
                padding-right: $footer-item-padding-right-mobile;
            }

            > * {
                padding-top: $footer-row-padding-top-mobile;

                > * {
                    max-width: 100%;

                    &:nth-child(4) {
                        .ms-footer__list {
                            display: inline-flex;
                        }

                        @for $i from 3 to ($footer-list-item-icon-number + 2) {
                            .ms-footer__list-item:nth-child(#{$i}) {
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-m) {
        .ms-footer {
            .ms-back-to-top[aria-hidden="false"] {
                display: block;
                opacity: 1;
                transform: translateX(0%);
            }
        }
    }
}
