$msv-checkout-description-margin-left: 20px;
$msv-checkout-option-margin-bottom: 20px;
$msv-checkout-delivery-options-price-margin-left: 12px;
$msv-checkout-delivery-options-description-margin-left: 0;

//style presets
:root {
    --msv-checkout-delivery-options-font-size: var(--msv-body-font-size-s);
    --msv-checkout-delivery-options-font-color: #{msv-gray-900};
}

.ms-checkout-delivery-options {
    &__price {
        @include font-content(var(--msv-font-weight-normal),
        var(--msv-checkout-delivery-options-font-size), $msv-line-height-m);
        color: var(--msv-checkout-delivery-options-font-color);
        margin-left: auto;
    }

    &__description {
        @include font-content(var(--msv-font-weight-normal),
        var(--msv-checkout-delivery-options-font-size), $msv-line-height-m);
        color: var(--msv-checkout-delivery-options-font-color);
        margin-left: $msv-checkout-description-margin-left;
    }

    &__option {
        margin-bottom: $msv-checkout-option-margin-bottom;
        display: flex;
        align-items: center;

        &-selected {
            display: flex;
            align-items: center;
            .ms-checkout-delivery-options__price {
                margin-left: $msv-checkout-delivery-options-price-margin-left;
            }

            .ms-checkout-delivery-options__description {
                margin-left: $msv-checkout-delivery-options-description-margin-left;
            }
        }
    }

    &__input-radio {
        @include form-input-radio();
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        &__description {
            flex: 0 0 60%;
            max-width: 60%;
        }

        &__price {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
            margin-left: 0;
        }
    }
}

.dyn-delivery-option-alert,
.dyn-delivery-option-sentence{
    margin-top: 10px;
    color: var(--tebu-blue);
    border-color: transparent;
    border: none;
    background-repeat: no-repeat;
    background-position: left center;
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: 400;
    padding: 1.5rem 1rem 1.5rem 50px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEuMDQzMTEwNjIsMjUuNzYzNTA5NCBMMTMuNjkzMjU1MywzLjMxODUzODQyIEMxNC4xMDAwMDc4LDIuNTk2ODQzMjYgMTUuMDE0Nzk1NCwyLjM0MTUzMTc5IDE1LjczNjQ5MDYsMi43NDgyODQzIEMxNS45NzUwNjc5LDIuODgyNzQ4MTkgMTYuMTcyMjgwOCwzLjA3OTk2MTA1IDE2LjMwNjc0NDcsMy4zMTg1Mzg0MiBMMjguOTU2ODg5NCwyNS43NjM1MDk0IEMyOS4zNjM2NDE5LDI2LjQ4NTIwNDYgMjkuMTA4MzMwNCwyNy4zOTk5OTIyIDI4LjM4NjYzNTMsMjcuODA2NzQ0NyBDMjguMTYxODQ2OCwyNy45MzM0MzcgMjcuOTA4MTc3MywyOCAyNy42NTAxNDQ3LDI4IEwyLjM0OTg1NTMsMjggQzEuNTIxNDI4MTgsMjggMC44NDk4NTUzLDI3LjMyODQyNzEgMC44NDk4NTUzLDI2LjUgQzAuODQ5ODU1MywyNi4yNDE5Njc0IDAuOTE2NDE4MjU2LDI1Ljk4ODI5NzkgMS4wNDMxMTA2MiwyNS43NjM1MDk0IFoiIHN0cm9rZT0iIzA3MzNCNiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PGxpbmUgeDE9IjE1IiB5MT0iMTMiIHgyPSIxNSIgeTI9IjE5IiBzdHJva2U9IiMwRjM3QUYiIHN0cm9rZS13aWR0aD0iMiIvPjxsaW5lIHgxPSIxNSIgeTE9IjIyIiB4Mj0iMTUiIHkyPSIyMyIgc3Ryb2tlPSIjMEYzN0FGIiBzdHJva2Utd2lkdGg9IjIiLz48L2c+PC9zdmc+');
    background-position: 10px center;
    background-color: var(--tebu-light-blue);
    text-align: left;
}
.dyn-delivery-option-sentence{
    margin-bottom: 20px;
}