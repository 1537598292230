$spacer-size-desktop: 2rem;
$spacer-size-mobile: 2rem;

.ms-spacer {
    height: $spacer-size-mobile;

    &.separator{
        margin-top: $spacer-size-mobile;
        border-top: 0.1rem solid #D9DBDD;
    }

    @media screen and (min-width: $msv-breakpoint-m) {
        & {
            height: $spacer-size-desktop;
        }
        &.separator {
            margin-top: $spacer-size-desktop;
            height: $spacer-size-desktop;
            border-top: 0.1rem solid #D9DBDD;
        }
    }

    &1x {
        height: 1rem;
    }

    &2x {
        height: 2rem;

        &.separator {
            margin-top: 2rem;
            border-top: 0.1rem solid #D9DBDD;
        }
    }

    &3x {
        height: 3rem;
    }

    &4x {
        height: 4rem;
    }

    &5x {
        height: 5rem;
    }

    &6x {
        height: 6rem;
    }

    &7x {
        height: 7rem;
    }

    &8x {
        height: 8rem;
    }

    &9x {
        height: 9rem;
    }

    &10x {
        height: 10rem;
    }

    &1x.separator {
        margin-top: 1rem;
        border-top: 0.1rem solid #D9DBDD;
    }

    &2x.separator {
        margin-top: 2rem;
        border-top: 0.1rem solid #D9DBDD;
    }

    &3x.separator {
        margin-top: 3rem;
        border-top: 0.1rem solid #D9DBDD;
    }

    &4x.separator {
        margin-top: 4rem;
        border-top: 0.1rem solid #D9DBDD;
    }

    &5x.separator {
        margin-top: 5rem;
        border-top: 0.1rem solid #D9DBDD;
    }

    &6x.separator {
        margin-top: 6rem;
        border-top: 0.1rem solid #D9DBDD;
    }

    &7x.separator {
        margin-top: 7rem;
        border-top: 0.1rem solid #D9DBDD;
    }

    &8x.separator {
        margin-top: 8rem;
        border-top: 0.1rem solid #D9DBDD;
    }

    &9x.separator {
        margin-top: 9rem;
        border-top: 0.1rem solid #D9DBDD;
    }

    &10x.separator {
        margin-top: 10rem;
        border-top: 0.1rem solid #D9DBDD;
    }
}
